import addIcon from 'assets/svg/add-square-outline.svg';
import pollImage from 'assets/image/poll-image.png';

export default function Sidebar() {
  return (
    <section className='w-[12%] h-screen fix top-0 left-0 bg-white pt-[80px]'>
      <section className='pt-2 px-2'>
        <button className='bg-black px-2 py-1 flex items-center gap-x-2 rounded-md align-bottom w-full'>
          <img src={addIcon} alt='add new poll' className='w-6' />
          <p className='text-white text-xs'>Add new poll</p>
        </button>
        {/* list of polls */}
        <section className='mt-3'>
          <div className='flex items-center justify-between'>
            <div className='flex w-5 h-5 items-center justify-center rounded-full bg-primary text-white text-xs'>
              <p>1</p>
            </div>
            {/* <div className='border-2 border-primary rounded-md'> */}
            <img src={pollImage} alt='' />
            {/* </div> */}
          </div>
        </section>
      </section>
    </section>
  );
}
