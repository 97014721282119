// Assets
import bell from 'assets/svg/bell.svg';
import menu from 'assets/svg/hamburger.svg';
import play from 'assets/svg/play2.svg';
import calender from 'assets/svg/calendar-2.svg';
import ChevronDownWhite from 'assets/svg/chevron-down-white.svg';
import plusWhite from 'assets/svg/plus-white.svg';
import { useNavigate } from 'react-router-dom';

// Libraries

export default function Navbar() {
  const navigate = useNavigate();

  return (
    <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
      <section className='w-[95%] mx-auto'>
        <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
          <section className='flex items-center justify-between w-full lg:w-[30%]'>
            <img src={menu} alt='open sidenav' className='block lg:hidden' />

            <div className='leading-tight text-right lg:text-left justify-items-end justify-self-end lg:self-start'>
              <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                Polls
              </h1>
              <p className='lg:text-sm text-xs mt-[-3px]'>
                Create engagement polls
              </p>
            </div>
          </section>

          <section className='gap-x-2 lg:flex hidden items-center justify-between'>
            <section onClick={(e) => e.preventDefault()}>
              <div
                className='bg-black text-white text-sm h-8 rounded-md flex gap-x-1 items-center px-5 cursor-pointer'
                onClick={() => navigate('/poll-setup/:pollId')}
              >
                <img src={plusWhite} alt='ellipsis icon' />
                <p>Create new poll</p>
                <img src={ChevronDownWhite} alt='ellipsis icon' />
              </div>
            </section>
            <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
              <img src={bell} alt='notification' className='w-4' />
            </div>
          </section>
        </div>
      </section>
    </nav>
  );
}
