//route guard
import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";

//components

import Team from "views/Admin/Team";
import Home from "views/Website/Home";
import AboutUs from "views/Website/About";
import OTP from "views/Authentication/OTP";
import Pricing from "views/Website/Pricing";
import Workspace from "views/Admin/Workspace";
import Dashboard from "views/Admin/Dashboard";
import ViewerMode from "views/User/ViewerMode";
import MyDocuments from "views/Admin/Documents";
import WaitingArea from "views/User/WaitingArea";
import Presentation from "views/Admin/Presentation";
import AdminLayout from "components/UI/AdminLayout";
import Register from "views/Authentication/Register";
import Onboarding from "views/Authentication/Onboarding";
import JoinPresentation from "views/User/JoinPresentation";
// import DocumentList from "views/Admin/Documents/PollCreation";
import ActivePresentation from "views/Admin/ActivePresentation";
import WebsiteLayout from "components/Website/Layout/WebsiteLayout";
import PresentationDetails from "views/Admin/Presentation/PresentationDetails";
import PresentationEnded from "views/User/PresentationStatus/PresentationEnded";
import Support from "views/Website/Support";
import Test from "views/Website/test";
import TeamPlanCheckout from "views/Admin/Team/Checkout";
import Directory from "views/Admin/Directory";
import Visitors from "views/Admin/Directory/Visitors";
import Leads from "views/Admin/Directory/Leads";
import PresentationMetrics from "views/Admin/Presentation/PresentationMetrics";
import Settings from "views/Admin/Settings";
import RemovedUser from "views/User/RemovedUser";
import WordCloudTest from "views/Admin/Test/WordCloudTest";
import MyBriefCase from "views/Admin/Briefcase";
import PresentationDetails_ from "views/Admin/Presentation/PresentationDetails_.tsx";
import BriefcaseDetails from "views/Admin/Briefcase/BriefcaseDetails";
import BriefcaseFileDetails from "views/Admin/Briefcase/BriefcaseFileDetails";
import BriefcaseLayout from "components/UI/BriefcaseLayout";
import { ForgotPassword } from "../views/Authentication/ForgotPassword";
import { ResetPassword } from "../views/Authentication/ResetPassword";
import ExLandingPage from "../views/external-files/presentation/ex-landing";
import { ExPresentations } from "../views/external-files/presentation/ex-presentations";
import SharedBriefcase from "views/Admin/SharedBriefcase";
import SharedBriefcaseLayout from "components/UI/SharedBriefcaseLayout";
import PollsLayout from "components/UI/PollsLayout";
import JoinSharedPresentation from "views/User/JoinSharedPresentation";
import Polls from "views/Admin/Polls";
import PollSetup from "views/Admin/PollSetup";
import BriefcasePreview from "views/Admin/Briefcase/BriefcasePreview/BriefcasePreview";

const {
  createBrowserRouter,
  Routes,
  Route,
  Outlet,
} = require("react-router-dom");
const { default: ErrorPage } = require("views/ErrorPage");
const { default: Login } = require("views/Authentication/Login");

export const router = createBrowserRouter([
  //website routes
  {
    path: "/",
    element: (
      <Routes>
        <Route
          path="/"
          element={
            <WebsiteLayout>
              <Home />
            </WebsiteLayout>
          }
        />
      </Routes>
    ),
  },

  // {
  //   path: "/test",
  //   element: <Test />,
  // },

  {
    path: "/about-us",
    element: (
      <WebsiteLayout>
        <AboutUs />
      </WebsiteLayout>
    ),
  },

  {
    path: "/pricing",
    element: (
      <WebsiteLayout>
        <Pricing />
      </WebsiteLayout>
    ),
  },

  {
    path: "/support",
    element: (
      <WebsiteLayout>
        <Support />
      </WebsiteLayout>
    ),
  },

  //admin routes

  {
    path: "/login",
    element: (
      <AuthRoute>
        <Login />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/signup",
    element: (
      <AuthRoute>
        <Register />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/ResetPassword",
    element: (
      <AuthRoute>
        <ResetPassword />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/OTP",
    element: (
      <AuthRoute>
        <OTP />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot-password",
    element: (
      <AuthRoute>
        <ForgotPassword />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/onboarding",
    element: <Onboarding />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <AdminLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "test",
        element: <WordCloudTest />,
      },
      {
        path: "mypresentations",
        element: <Presentation />,
      },
      {
        path: "presentation-details_",
        element: <PresentationDetails_ />,
      },

      {
        path: "mybriefcase",
        // element: <MyBriefCase />,
        element: <BriefcaseLayout />,
        children: [
          {
            path: "",
            element: <MyBriefCase />,
          },
          {
            path: "details/:spaceId",
            element: <BriefcaseDetails />,
          },
          {
            path: "details/:spaceId/:fileId",
            element: <BriefcaseFileDetails />,
          },
        ],
      },

      {
        path: "workspace",
        element: <Workspace />,
      },

      // {
      //   path: 'directory',
      //   element: <Directory />,
      //   children: [],
      // },
      { path: "visitors", element: <Visitors /> },
      { path: "leads", element: <Leads /> },

      {
        path: "polls",
        element: <PollsLayout />,
        children: [
          {
            path: "",
            element: <Polls />,
          },
        ],
      },

      {
        path: "presentation-metric/:presentationId",
        element: <PresentationMetrics />,
      },

      {
        path: "mydocuments",
        element: <MyDocuments />,
      },

      // {
      //   path: "mydocuments/:id",
      //   element: <DocumentList />,
      // },

      {
        path: "team",
        element: <Team />,
      },

      {
        path: "checkout/:id",
        element: <TeamPlanCheckout />,
      },

      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },

  {
    path: "presentation-details/:presentationId",
    element: (
      <ProtectedRoute>
        <PresentationDetails />
      </ProtectedRoute>
    ),
  },

  {
    path: "active-presentation/:presentationId",
    element: (
      <ProtectedRoute>
        <ActivePresentation />
      </ProtectedRoute>
    ),
  },

  // shared briefcase
  {
    path: "shared-briefcase/:presentationId",
    element: (
      <ProtectedRoute>
        {/* <SharedBriefcaseLayout> */}
        <SharedBriefcase />
        {/* </SharedBriefcaseLayout> */}
      </ProtectedRoute>
    ),
  },
  {
    path: "briefcase-preview/:spaceId",
    element: (
      <ProtectedRoute>
        <BriefcasePreview />
      </ProtectedRoute>
    ),
  },

  // Polls setup
  {
    path: "poll-setup/:pollId",
    element: (
      <ProtectedRoute>
        <PollSetup />
      </ProtectedRoute>
    ),
  },

  //user's portal
  {
    path: "join-presentation/:presentationId",
    element: <JoinPresentation />,
  },
  {
    path: "waiting-area/",
    element: <WaitingArea />,
  },

  {
    path: "viewer-mode/:friendlyId",
    element: <ViewerMode />,
  },

  {
    path: "presentation-ended",
    element: <PresentationEnded />,
  },
  {
    path: "removed",
    element: <RemovedUser />,
  },

  //External pages

  {
    path: "/external",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ExLandingPage />,
      },
      {
        path: "presentations",
        element: <ExPresentations />,
      },
    ],
  },
  // access shared presentation
  {
    path: "access-shared-presentation/:presentationId",
    element: <JoinSharedPresentation />,
  },
]);
