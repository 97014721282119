import { useEffect, useState } from 'react';

// components

//assets
// import bell from 'assets/svg/bell.svg';
// import menu from 'assets/svg/hamburger.svg';
// import ellipsisVertical from 'assets/svg/ellipsis-vertical.svg';
// import eyeI from 'assets/svg/eye-2.svg';
// import chevronDownWhite from 'assets/svg/chevron-down-white.svg';
// import dot from 'assets/svg/_Dot.svg';
import avatar1 from 'assets/image/avatarFB.png';
import avatar2 from 'assets/image/avatarOl.png';
import clock from 'assets/svg/clock-icon.svg';
import ChevronDownBlack from 'assets/svg/chevron-down-black.svg';
import lines from 'assets/image/briefcaselayerlines.png';
import exportIconBlack from 'assets/svg/export-icon-black.svg';
// import play from 'assets/svg/play2.svg';
// import calender from 'assets/svg/calendar-2.svg';
// import filesBanner from 'assets/image/files-banner.png';

// Libraries
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import FileVisits from './Tables/FileVisits';
import FileAnalytics from './Tables/FileAnalytics';
import FileUsage from './Tables/FileUsage';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import {
  getBriefcase,
  getBriefcaseDocument,
  getDocumentAnalytics,
  getDocumentUsage,
  // getBriefcaseDocuments,
  getDocumentVisits,
} from 'store/Briefcase/briefcaseSlice';
import { imageStream } from 'utils/imageStream';
// import Nav from 'components/Admin/BriefCase/Nav';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import moment from 'moment';
import { getInitials } from 'utils/formatter';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

// types
interface TeamInfoData {
  id: string;
}

export default function BriefcaseFileDetails() {
  //
  // let [searchParams] = useSearchParams();
  const { spaceId, fileId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { teamInfo } = useSelector((state: RootState) => state.teams);
  const briefcaseDocument = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocument.data
  );
  const briefcaseDocumentVisits = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocument.visits
  );
  const briefcase = useSelector(
    (state: RootState) => state.briefcase.briefcase.data
  );
  const [filePreview, setFilePreview] = useState('');

  useEffect(() => {
    console.log(teamInfo);
    // console.log(data);
    if (teamInfo.data && spaceId) {
      const teamInfoData = teamInfo.data as TeamInfoData;
      const teamId = teamInfoData.id;
      dispatch(getBriefcase({ spaceId, teamId }));
    }

    const getStream = async (id: string) => {
      console.log('id----', id);
      const result = await imageStream(id, true, 115, 112);
      setFilePreview(result ?? '');
      // setIsLoading(false);
    };

    if (spaceId && fileId) {
      const documentId = fileId;
      dispatch(getBriefcaseDocument(documentId));
      dispatch(getDocumentAnalytics({ spaceId, documentId }));
      dispatch(getDocumentVisits({ spaceId, documentId }));
      dispatch(getDocumentUsage({ spaceId, documentId }));
      getStream(fileId);
    }
  }, []);

  // States
  const [tab, setTab] = useState(0);

  return (
    <section>
      {/* File Details Banner */}
      {briefcaseDocument && (
        <>
          <section className='mt-20'>
            <div className='bg-[#F6F3EF] w-full h-full rounded-xl p-5 border border-[#F6F3EF] relative'>
              <div className='w-full flex items-center gap-x-4'>
                <img
                  src={filePreview}
                  alt='file'
                  className='w-[115px] h-[112px] rounded-md object-cover object-center'
                />
                {/* <img src={filesBanner} alt='file' /> */}
                <div>
                  <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                    {briefcaseDocument.title}
                  </h1>
                  <section className='flex gap-x-5 items-center text-sm'>
                    <p>
                      {/* Description goes here if theres any at all Description
                      goes here if theres any at all */}
                    </p>
                    <div className='flex items-center gap-x-2'>
                      <img src={clock} alt='clock' />
                      {/* <p>Last updated 5 min ago</p> */}
                      {briefcaseDocumentVisits && (
                        <p>
                          {moment
                            .utc(briefcaseDocumentVisits.at(0)?.date)
                            .fromNow()}
                        </p>
                      )}
                    </div>
                  </section>
                  <div className='mt-3'>
                    {/* <AvatarGroup>
                      <Avatar image={avatar1} size='normal' shape='circle' />
                      <Avatar image={avatar2} size='normal' shape='circle' />
                      <Avatar image={avatar1} size='normal' shape='circle' />
                      <Avatar label='+2' shape='circle' size='normal' />
                    </AvatarGroup> */}
                    <AvatarGroup>
                      {briefcase &&
                        briefcase.members?.map((item, index) => (
                          <Avatar
                            label={getInitials(item.fullName)}
                            shape='circle'
                            size='normal'
                            key={index}
                          />
                        ))}
                    </AvatarGroup>
                  </div>
                </div>
              </div>
              <img
                src={lines}
                alt='briefcase'
                className=' absolute right-0 top-0'
              />
            </div>
          </section>

          {/* tabs */}
          <section className='mt-5 mb-20'>
            <section className='flex gap-x-4 items-center justify-between'>
              <section className='flex gap-x-2 border-b'>
                <button
                  onClick={() => setTab(0)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 0 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Visits
                </button>

                <button
                  onClick={() => setTab(1)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 1 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Analytics
                </button>

                <button
                  onClick={() => setTab(2)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 2 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Usage
                </button>
              </section>
              <section className='flex items-center gap-x-2'>
                <div className='bg-[#F6F6F6] text-[#333333] text-sm h-8 rounded-md flex gap-x-1 items-center cursor-pointer border border-[#E2E2E2] px-3'>
                  <img src={exportIconBlack} alt='export icon' />
                  <p>Export</p>
                  <img src={ChevronDownBlack} alt='more' />
                </div>
              </section>
            </section>

            <section className='mt-1 mb-8 pb-20'>
              {tab === 0 && <FileVisits />}
              {tab === 1 && <FileAnalytics />}
              {tab === 2 && <FileUsage />}
            </section>
          </section>
        </>
      )}
    </section>
  );
}
