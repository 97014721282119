import React, { useEffect, useRef, useState } from 'react';

// Components
import AllFiles from './Tables/AllFiles';

// Assets
import dot from 'assets/svg/_Dot.svg';
import ellipsisVertical from 'assets/svg/ellipsis-vertical.svg';
import clock from 'assets/svg/clock-icon.svg';
import briefcaseIMG from 'assets/svg/briefcase-img.svg';
import ChevronDownWhite from 'assets/svg/chevron-down-white.svg';
import plusWhite from 'assets/svg/plus-white.svg';
import lines from 'assets/image/briefcaselayerlines.png';
import link from 'assets/svg/link2.svg';
import addCircle from 'assets/svg/add-circle2.svg';
import divider from 'assets/svg/divider.svg';
import addCircleOrange from 'assets/svg/add-circle-orange.svg';
import camera from 'assets/svg/cameraIcon.svg';
import closeIcon from 'assets/svg/close-icon.svg';

// Libraries
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import { Link, useSearchParams, useParams } from 'react-router-dom';
import ShareHistory from './Tables/ShareHistory';
// import AuditTrail from './Tables/AuditTrail';
import Analytics from './Tables/Analytics';
import { Dropdown, Menu, Modal } from 'antd';
import UploadDocuments from './Modals/UploadDocuments';
import {
  getBriefcase,
  getBriefcaseDocuments,
  getFolders,
  getPresentationHistory,
  getShareHistory,
  updateBriefcase,
} from 'store/Briefcase/briefcaseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { getInitials } from 'utils/formatter';
import PresentationHistory from './Tables/PresentationHistory';
import AddMembers from './Modals/AddMembers';
import moment from 'moment';
import CreateTag from './Modals/CreateTag';
import { imageStream } from 'utils/imageStream';
import { toast } from 'sonner';
import AddFolder from './Modals/AddFolder';
import AddFile from './Modals/AddFile';

//Types
interface TeamInfoData {
  id: string;
}

interface CreateSpaceModalRef {
  resetModal: () => void;
}

export default function BriefcaseDetails() {
  //url param
  let [searchParams] = useSearchParams();
  const { spaceId } = useParams();

  // refs
  const createSpaceModal = useRef<CreateSpaceModalRef>(null);

  //
  const dispatch = useDispatch<AppDispatch>();

  // States
  const [tab, setTab] = useState(0);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [addFolder, setAddFolder] = useState(false);
  const [isAddMemeberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [parentFolderId, setParentFolderId] = useState<string | null>(null);
  const [FolderGroupId, setFolderGroupId] = useState<string | null>(null);

  // const [isLoading, setIsLoading] = useState(true);

  // Store
  const briefcaseDocuments = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocuments
  );
  const { teamInfo } = useSelector((state: RootState) => state.teams);
  const briefcase = useSelector(
    (state: RootState) => state.briefcase.briefcase.data
  );
  const briefcaseMembers = useSelector(
    (state: RootState) => state.briefcase.briefcase.data?.members
  );

  // for setting the banner ==================================>
  const getStream = async (id: string) => {
    console.log('id----', id);
    const result = await imageStream(id, true, 500, 500);
    // setIsLoading(false);
    setBackgroundImage(result ?? '');
  };

  useEffect(() => {
    // setBackgroundImage(''); //trying to solve prev bg img showing for a split sec

    if (briefcase?.bannerId) {
      getStream(briefcase.bannerId);
    }

    spaceId && dispatch(getFolders(spaceId));
    // return () => {
    //   setBackgroundImage(''); //removes the background image when the component unmounts
    // };
  }, [briefcase?.bannerId]);

  const updateThumbnail = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files ? e.currentTarget.files[0] : null;
    if (!file) {
      return;
    }

    // Store the current thumbnail before updating
    const previousThumbnailImg = backgroundImage;

    // Optimistic UI update
    const objectUrl = URL.createObjectURL(file);
    setBackgroundImage(objectUrl);

    const formData = new FormData(); //

    if (file && briefcase) {
      formData.append('SpaceId', briefcase.id);
      formData.append('Banner', file);
      formData.append('Title', briefcase.title);
      formData.append('Description', briefcase.description);
      formData.append('Tags', briefcase.tags);
    }

    // Clear the file input value to allow selecting the same file again
    if (e.currentTarget?.value) {
      e.currentTarget.value = '';
    }

    const { type } = await dispatch(updateBriefcase(formData));
    if (type.includes('fulfilled')) {
      toast.success('Thumbnail updated successfully');
    } else {
      // Handle error by reverting to the previous image
      setBackgroundImage(previousThumbnailImg);
    }
  };

  //============================================end of banner implementation

  useEffect(() => {
    if (teamInfo.data && spaceId) {
      const teamInfoData = teamInfo.data as TeamInfoData;
      const teamId = teamInfoData.id;
      dispatch(getBriefcaseDocuments(spaceId));
      dispatch(getBriefcase({ spaceId, teamId }));
      // review getting share history & presentation history
      dispatch(getShareHistory(spaceId));
      dispatch(getPresentationHistory(spaceId));
    }
  }, [dispatch, spaceId, teamInfo.data]);

  //useeffects
  useEffect(() => {
    const tabParam = searchParams.get('tab')?.toLowerCase();
    !tabParam && setTab(0);
    tabParam === 'all-files' && setTab(0);
    tabParam === 'links' && setTab(1);
    tabParam === 'presentation-history' && setTab(2);
    tabParam === 'analytics' && setTab(3);
    // tabParam === 'audit-trail' && setTab(4);
  }, [searchParams]);

  const handleFileUploadCancel = () => {
    setIsFileUploadModalOpen(false);
  };
  const handleAddFolder = () => {
    setAddFolder(false);
  };

  const openCreateSpaceModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setOpenRenameModal(false);
    createSpaceModal.current?.resetModal();
  };

  //variables
  const items_ = [
    {
      key: '1',
      label: (
        <div className='flex gap-x-2' onClick={() => setAddFolder(true)}>
          <img src={divider} alt='rename file' className='w-4' />
          <p>Add section</p>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={addCircle} alt='metrics file' className='w-4' />
          <p>Upload files</p>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div className='flex gap-x-2' onClick={() => setAddFolder(true)}>
          <img src={addCircle} alt='request file' className='w-4' />
          <p>Create a folder</p>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className='flex gap-x-2'>
          <img src={link} alt='request file' className='w-4' />
          <p>Request a file</p>
        </div>
      ),
    },
  ];

  return (
    // <AddMembers />

    <section className='relative'>
      <section>
        {/* Details banner */}
        <section className='mt-20 relative'>
          {backgroundImage ? (
            <div className='h-[130px] w-full rounded-xl overflow-hidden bg-blue400'>
              <img
                src={backgroundImage}
                alt='background frame'
                className='w-full h-full object-cover'
                role='presentation'
              />
            </div>
          ) : (
            <div className='h-[130px] w-full rounded-xl overflow-hidden bg-white'></div>
          )}

          {/* banner */}
          <div className='h-[144px] w-full bg-transparent absolute -bottom-[70%] left-0 px-5'>
            <div className='bg-[#FEFEFD] w-full h-full rounded-xl p-5 border border-[#F6F3EF] relative'>
              <div className='w-full'>
                <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                  {briefcase ? briefcase.title : ''}
                </h1>
                <section className='flex gap-x-5 items-center text-sm'>
                  <p>Collection of my shared briefcase</p>
                  <div
                    className='flex gap-x-2 cursor-pointer'
                    onClick={() => openCreateSpaceModal()}
                  >
                    <div className='flex gap-x-2 items-center'>
                      {briefcase &&
                        briefcase.tags &&
                        briefcase.tags
                          ?.split(',')
                          .slice(0, 2)
                          .map((item, index) => (
                            <div
                              className='flex items-center gap-x-2 bg-[#F5F5F5] border border-[#DFDFDF] rounded-full w-fit px-2 py-1'
                              key={index}
                            >
                              <img src={dot} alt='profile' className='' />
                              <div className='text-sm font-medium capitalize'>
                                <p>{item}</p>
                              </div>
                            </div>
                          ))}
                    </div>
                    {briefcase && briefcase.tags?.split(',').length > 2 && (
                      <div className='flex items-center gap-x-2 bg-[#F5F5F5] border border-[#DFDFDF] rounded-full w-fit px-2 py-1 '>
                        <div className='text-sm font-medium capitalize'>
                          <p>+ {briefcase.tags?.split(',').length - 2}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='flex items-center gap-x-2'>
                    <img src={clock} alt='clock' />
                    <p>
                      {`last updated ${
                        briefcase
                          ? moment.utc(briefcase.lastModified).fromNow()
                          : ''
                      }`}
                    </p>
                  </div>
                </section>
                <div className='mt-3 flex gap-x-4'>
                  <AvatarGroup>
                    {briefcase &&
                      briefcase.members?.map((item, index) => (
                        <Avatar
                          label={getInitials(item.fullName)}
                          shape='circle'
                          size='normal'
                          key={index}
                        />
                      ))}
                  </AvatarGroup>
                  <div className='relative'>
                    <button
                      className='bg-white border border-primary rounded-md py-1 flex gap-x-2 px-2 items-center justify-center '
                      onClick={() => {
                        setIsAddMemberModalOpen(true);
                      }}
                    >
                      <img src={addCircleOrange} alt='add member' />
                      <p className='text-sm text-primary font-medium'>
                        Add member
                      </p>
                    </button>
                    {/* Add members modal */}
                    {briefcaseMembers && briefcaseMembers.length > 0 && (
                      <AddMembers
                        isAddMemeberModalOpen={isAddMemeberModalOpen}
                        setIsAddMemberModalOpen={setIsAddMemberModalOpen}
                      />
                    )}
                  </div>
                </div>
              </div>
              <img
                src={briefcaseIMG}
                alt='briefcase'
                className='absolute right-20 top-0 pointer-events-none'
              />
              <img
                src={lines}
                alt='briefcase'
                className=' absolute right-0 top-0 pointer-events-none'
              />
            </div>
          </div>

          {/* upload banner */}
          <div className='absolute top-4 left-6 cursor-pointer'>
            <label htmlFor='upload-banner'>
              <img
                src={camera}
                alt='uplaod banner'
                className='cursor-pointer'
              />
              <input
                onChange={updateThumbnail}
                id='upload-banner'
                type='file'
                className='border cursor-pointer opacity-0 w-[40px] hidden'
              />
            </label>
          </div>
        </section>

        {/* tabs */}
        <section className='my-20 mt-[110px]'>
          <section className='flex gap-x-4 items-center justify-between'>
            <section className='flex gap-x-2 border-b'>
              <Link to={`/admin/mybriefcase/details/${spaceId}?tab=all-files`}>
                <button
                  onClick={() => setTab(0)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 0 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  All files
                </button>
              </Link>

              <Link to={`/admin/mybriefcase/details/${spaceId}?tab=links`}>
                <button
                  onClick={() => setTab(1)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 1 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Links
                </button>
              </Link>

              <Link
                to={`/admin/mybriefcase/details/${spaceId}?tab=presentation-history`}
              >
                <button
                  onClick={() => setTab(2)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 2 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Presentation history
                </button>
              </Link>
              <Link to={`/admin/mybriefcase/details/${spaceId}?tab=analytics`}>
                <button
                  onClick={() => setTab(3)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 3 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Analytics
                </button>
              </Link>
              {/* <Link
                to={`/admin/mybriefcase/details/${spaceId}?tab=audit-trail`}
              >
                <button
                  onClick={() => setTab(4)}
                  className={`px-4 pb-2 cursor-pointer text-sm  ${
                    tab === 4 && 'border-b-2  text-primary border-b-primary'
                  }`}
                >
                  Audit trail
                </button>
              </Link> */}
            </section>
            <section className='flex items-center gap-x-2'>
              {/* <div className='h-8 rounded-md w-8 border border-[#E2E2E2] cursor-pointer flex items-center justify-center'>
                <img src={ellipsisVertical} alt='ellipsis icon' />
              </div> */}
              {tab === 0 && spaceId && (
                <section onClick={(e) => e.preventDefault()}>
                  <Dropdown
                    trigger={['click']}
                    dropdownRender={(menu) => (
                      <Menu>
                        {items_.map((item) => {
                          return (
                            <Menu.Item key={item.key}>{item.label}</Menu.Item>
                          );
                        })}
                      </Menu>
                    )}
                  >
                    <div className='bg-black text-white text-sm h-8 rounded-md flex gap-x-1 items-center px-5 cursor-pointer'>
                      <img src={plusWhite} alt='ellipsis icon' />
                      <p>Add content</p>
                      <img src={ChevronDownWhite} alt='ellipsis icon' />
                    </div>
                  </Dropdown>
                </section>
              )}
            </section>
          </section>

          <section className='my-8 pb-20'>
            {tab === 0 && spaceId && (
              <AllFiles
                spaceId={spaceId}
                briefcaseDocuments={briefcaseDocuments.data}
              />
            )}
            {tab === 1 && <ShareHistory />}
            {tab === 2 && <PresentationHistory />}
            {tab === 3 && (
              <Analytics briefcaseDocuments={briefcaseDocuments.data} />
            )}
            {/* {tab === 4 && (
              <AuditTrail briefcaseDocuments={briefcaseDocuments.data} />
            )} */}
          </section>
        </section>

        {/* Modals */}
        {spaceId && (
          <>
            {/* <Modal
              open={isFileUploadModalOpen}
              onCancel={handleFileUploadCancel}
              footer={null}
            >
              <UploadDocuments
                closeModal={handleFileUploadCancel}
                spaceId={spaceId}
                groupId={FolderGroupId}
              />
            </Modal> */}

            {/* Add files */}
            <Modal
              open={isFileUploadModalOpen}
              onCancel={handleFileUploadCancel}
              footer={null}
              width={1100}
              centered
              destroyOnClose={true}
            >
              <AddFile
                closeAddFileModal={handleFileUploadCancel}
                spaceId={spaceId}
                groupId={FolderGroupId}
              />
            </Modal>

            {/* Add folder */}
            <Modal
              open={addFolder}
              onCancel={handleAddFolder}
              footer={null}
              closeIcon={<img src={closeIcon} alt='close' />}
              width={452}
            >
              <AddFolder
                closeModal={handleAddFolder}
                spaceId={spaceId}
                parentId={parentFolderId}
              />
            </Modal>
          </>
        )}

        {briefcase && (
          <Modal
            footer={null}
            centered={false}
            open={isModalOpen}
            onCancel={handleCancel}
            style={{
              top: 20,
            }}
          >
            <CreateTag closeModal={handleCancel} briefcaseDetails={briefcase} />
          </Modal>
        )}
      </section>
    </section>
  );
}
