import Sidebar from 'components/UI/SharedBriefcaseLayout/Sidebar';
import { useEffect, useState } from 'react';
import bg_ from 'assets/image/bg-marketing-goals.png';
import arrowLeft from 'assets/svg/arrow-left-circle.svg';
import arrowLeftGray from 'assets/svg/arrow-left-circle-gray.svg';
import arrowRight from 'assets/svg/arrow-right-circle.svg';
import arrowRightGray from 'assets/svg/arrow-right-circle-gray.svg';
import addComment from 'assets/svg/add-comment.svg';
import download from 'assets/svg/download-icon-white.svg';
import print from 'assets/svg/print-icon.svg';
import share from 'assets/svg/share.svg';

//utils
import { imageStream } from 'utils/imageStream';

// Liberies
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

// Slice
import {
  setNextDisplayDoc,
  setPrevDisplayDoc,
} from 'store/SharedPresentations/sharedPresentationsSlice';
import { getPresentation } from 'store/SharedPresentations/sharedPresentationsSlice';

interface Document {
  folderId: string;
  parentId: string | null;
  userId: string;
  title: string;
  url: string;
  note: string | null;
  size: number;
  previousVersion: string | null;
  version: number;
  position: number;
  dateAdded: string; // You can use `Date` if you want to work with Date objects.
  dateModified: string; // Same as above.
  deleted: boolean;
  dateDeleted: string | null;
  views: number;
  downloads: number;
  id: string;
}

interface Folder {
  id: string;
  title: string;
  dateCreated: string; // Or `Date`
  dateModified: string; // Or `Date`
  subFolders: Folder[]; // Array of folders
  documents: Document[]; // Array of documents
}

export default function SharedBriefcase() {
  const { presentationId } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  // State
  const [diplayDocument, setDisplayDocument] = useState<string>('');

  // Store
  const displayDocuments = useSelector(
    (state: RootState) => state.sharePresentation.flattedDisplayDocuments
  );
  const displayDocumentIndex = useSelector(
    (state: RootState) => state.sharePresentation.displayDocIndex
  );

  // useeffects
  useEffect(() => {
    presentationId && dispatch(getPresentation(presentationId));
  }, [dispatch, presentationId]);

  // For setting display image, review later
  useEffect(() => {
    const fetchImageStream = async () => {
      const url = await imageStream(
        displayDocuments[displayDocumentIndex]?.documentId,
        false
      );
      url && setDisplayDocument(url);
    };

    displayDocuments && fetchImageStream();
  }, [displayDocumentIndex, displayDocuments]);

  const [isOpen, isPollDrawerOpen] = useState(false);

  return (
    <section className='relative min-h-screen '>
      <section
        className={`transition-all duration-500  ease-in-out fixed lg:translate-x-0 lg:w-[18%]  w-[70%]   z-50 ${
          isOpen ? ' translate-x-0' : ' translate-x-[-1000px]'
        } `}
      >
        <Sidebar />
      </section>
      <section>
        <section
          className={`absolute right-0 lg:w-[82%] p-5 w-full bg-white h-full`}
        >
          {/* Main content */}
          <section className='p-5 bg-gray100 w-full rounded-xl h-[607px] flex items-center justify-center overflow-hidden'>
            {/* <img src={bg_} alt='file' /> */}
            <img
              src={diplayDocument}
              alt='file'
              className='max-h-[607px] max-w-full'
            />
          </section>

          {/* controler */}
          <section className='flex justify-between mt-5 pb-20'>
            <section className='flex items-center gap-x-5'>
              <div
                className='bg-[#F5F5F5] flex gap-x-3 px-5 py-1 rounded-lg cursor-pointer'
                onClick={() => dispatch(setPrevDisplayDoc())}
              >
                <img src={arrowLeft} alt='previous slide' />
                <p className=''>Previous slide</p>
              </div>
              <div className='bg-primary rounded-[20px] text-black px-2 py-1'>
                <p className='font-medium'>{`${displayDocumentIndex + 1} of ${
                  displayDocuments.length
                }`}</p>
              </div>
              <div
                className='bg-[#F5F5F5] flex gap-x-3 px-5 py-1 rounded-lg cursor-pointer'
                onClick={() => dispatch(setNextDisplayDoc())}
              >
                <p>Next slide</p>
                <img src={arrowRight} alt='previous slide' />
              </div>
            </section>
            {/*  */}
            <section className='bg-[#363636] py-1 px-5 gap-x-5 flex rounded-lg'>
              <div className='cursor-pointer'>
                <img src={addComment} alt='add comment' />
              </div>
              <div className='cursor-pointer'>
                <img src={print} alt='print' />
              </div>
              <div className='cursor-pointer'>
                <img src={download} alt='download' />
              </div>
              <div className='cursor-pointer'>
                <img src={share} alt='share' />
              </div>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}

// {
//   "presentation": {
//       "spaceId": "88a853a6-80fb-4bd3-87bd-9c889b26d6fb",
//       "friendlyId": "7689488359",
//       "title": "Nakachukwu2",
//       "thumbnail": "",
//       "userId": "5cdda39c-2162-4d51-ad3c-617cb97c74f4",
//       "dateAdded": "2024-10-10T08:23:54.635813",
//       "expiry": null,
//       "startTime": null,
//       "endTime": null,
//       "scheduledTime": "2024-10-10T08:23:47.011",
//       "visibility": false,
//       "currentSlide": 0,
//       "allowedDomains": null,
//       "passcode": "",
//       "createdBy": "Nana 24",
//       "status": 1,
//       "authMode": 1,
//       "type": 0,
//       "canDownload": 0,
//       "questionsAllowed": true,
//       "watermark": null,
//       "deleted": false,
//       "currentItem": null,
//       "duration": 0,
//       "questions": 0,
//       "visitors": 0,
//       "views": 0,
//       "id": "8897d12a-d348-4e1d-ad4e-c454dae47727"
//   },
//   "doc": [
//       {
//           "id": "97cec116-3f7b-4f93-aaa3-3e2b457bda33",
//           "title": "General",
//           "docs": [
//               {
//                   "sessionId": "8897d12a-d348-4e1d-ad4e-c454dae47727",
//                   "sessionType": 0,
//                   "documentId": "1a297682-ef67-4b5e-8df1-94abc1dba699",
//                   "title": "generaldoc1",
//                   "note": null,
//                   "position": 2,
//                   "url": "https://thepresenta.blob.core.windows.net/documents-2024-oct/doc_a8c4724a-054b-4209-ba07-7ee1e5c79775.png",
//                   "type": "File",
//                   "deleted": false,
//                   "id": "468d762d-90fd-41c3-8818-200fe7cf2304"
//               }
//           ],
//           "subs": []
//       },
//       {
//           "id": "de7a2ab7-3a4f-43a1-9c09-615b000eb50d",
//           "title": "Marketing deck",
//           "docs": [
//               {
//                   "sessionId": "8897d12a-d348-4e1d-ad4e-c454dae47727",
//                   "sessionType": 0,
//                   "documentId": "ed3d1642-a341-46a2-9f48-8005fc98d9ec",
//                   "title": "construction.jpg",
//                   "note": null,
//                   "position": 3,
//                   "url": "https://thepresenta.blob.core.windows.net/documents-2024-oct/doc_0f03ee9a-16c5-4965-8b55-79a237134ed6.jpg",
//                   "type": "File",
//                   "deleted": false,
//                   "id": "480c608e-18b0-4bee-b3ab-dc18e4728570"
//               },
//               {
//                   "sessionId": "8897d12a-d348-4e1d-ad4e-c454dae47727",
//                   "sessionType": 0,
//                   "documentId": "7575c265-b5ca-4253-b7bc-11e2feb6e4e4",
//                   "title": "cat2.jpeg",
//                   "note": null,
//                   "position": 4,
//                   "url": "https://thepresenta.blob.core.windows.net/documents-2024-oct/doc_62cc83e8-7f24-44f3-869d-64f84ba8523c.jpeg",
//                   "type": "File",
//                   "deleted": false,
//                   "id": "8bf81a91-0344-4c9a-9eba-84e0f7dbbe42"
//               }
//           ],
//           "subs": []
//       },
//       {
//           "id": "0798ca7d-6610-454c-81a4-a6ca66b61e08",
//           "title": "Neo Colonialism",
//           "docs": [
//               {
//                   "sessionId": "8897d12a-d348-4e1d-ad4e-c454dae47727",
//                   "sessionType": 0,
//                   "documentId": "55145c33-fab3-4df0-a138-42848a8aa212",
//                   "title": "image2.png",
//                   "note": null,
//                   "position": 5,
//                   "url": "https://thepresenta.blob.core.windows.net/documents-2024-oct/doc_384c4600-8c3d-4871-8c98-e6ee7b8c6fdf.png",
//                   "type": "File",
//                   "deleted": false,
//                   "id": "85142313-92c6-49dd-84c6-85e304086892"
//               },
//               {
//                   "sessionId": "8897d12a-d348-4e1d-ad4e-c454dae47727",
//                   "sessionType": 0,
//                   "documentId": "3d47bf80-c6d7-48ba-b02c-e86fa015c913",
//                   "title": "image.png",
//                   "note": null,
//                   "position": 6,
//                   "url": "https://thepresenta.blob.core.windows.net/documents-2024-oct/doc_12727830-eb26-4390-8279-662f1fbc9b4a.png",
//                   "type": "File",
//                   "deleted": false,
//                   "id": "302fea76-e1a2-43a2-80b1-425a2335641f"
//               },
//               {
//                   "sessionId": "8897d12a-d348-4e1d-ad4e-c454dae47727",
//                   "sessionType": 0,
//                   "documentId": "9308da90-b03d-4620-9db5-3086dd500a6a",
//                   "title": "image3.png",
//                   "note": null,
//                   "position": 7,
//                   "url": "https://thepresenta.blob.core.windows.net/documents-2024-oct/doc_65fc6ab1-9735-456e-af7e-66978b7abf05.png",
//                   "type": "File",
//                   "deleted": false,
//                   "id": "2ac52923-671b-4194-a254-8f9f679c7aba"
//               },
//               {
//                   "sessionId": "8897d12a-d348-4e1d-ad4e-c454dae47727",
//                   "sessionType": 0,
//                   "documentId": "c70e9a77-9be7-44c3-b1ba-1972a4161864",
//                   "title": "Batman-PowerPoint-Template.pptx",
//                   "note": null,
//                   "position": 8,
//                   "url": "https://thepresenta.blob.core.windows.net/documents-2024-oct/doc_26f52d3f-38ac-4d4e-acd7-6353df35e2cd.pptx",
//                   "type": "File",
//                   "deleted": false,
//                   "id": "e1e05dfe-91fc-4653-a328-1463998e0e13"
//               }
//           ],
//           "subs": []
//       }
//   ]
// }
