import { useEffect, useState } from 'react';

// Assets
import arrowDown from 'assets/svg/arrowDown.svg';
import arrowUp from 'assets/svg/arrowUp.svg';
import newfolder from 'assets/svg/newfolder.svg';
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import presentationIcon from 'assets/svg/presention-chart-icon.svg';
import eyeIcon from 'assets/svg/eye.svg';
import inboxIcon from 'assets/svg/inbox-arrow-down.svg';
import moveFile from 'assets/svg/move.svg';
import trash from 'assets/svg/trash.svg';
import rename from 'assets/svg/rename.svg';
import link from 'assets/svg/link.svg';
import details from 'assets/svg/details.svg';
import editIcon from 'assets/svg/edit.svg';
import sectionIcon from 'assets/svg/section-icon2.svg';
import dragNdropIcon from 'assets/svg/drag-drop-icon.svg';
import access from 'assets/svg/access.svg';

// Libraries
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Dropdown, Menu, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

// Slices
import {
  getBriefcaseDocuments,
  getFolder,
  removeBriefcaseFile,
  setFolders,
  setDraggedItem,
} from 'store/Briefcase/briefcaseSlice';

// Components
import FileThumbnail from 'components/Admin/BriefCase/FileThumbnail';
import IsPrivate from 'components/Admin/BriefCase/IsPrivate';
import { Column } from 'primereact/column';
import ConfirmationModal from 'components/UI/Modal/ConfirmationModal';
import { toast } from 'react-toastify';
import UploadDocuments from 'views/Admin/Briefcase/Modals/UploadDocuments';

//Types
interface BriefcaseDocuments {
  spaceId: string;
  documentId: string;
  groupId: string;
  parentGroup: string | null;
  group: string;
  isPrivate: boolean;
  title: string;
  url: string;
  views: number;
  downloads: number;
  eventCount: number;
  dateAdded: string; // ISO date string format
  dateModified: string; // ISO date string format
  id: string;
}

interface BriefcaseFolderInterface {
  spaceId: string;
  parentId: string | null;
  title: string;
  dateCreated: string;
  deleted: boolean;
  id: string;
}
interface BriefcaseGroupFolders {
  id: string;
  title: string;
  dateCreated: string; // ISO date string format
  subGroups: BriefcaseFolderInterface[]; // Assuming subGroups is an array of similar BriefcaseFolder objects
  documents: BriefcaseDocuments[]; // Array of documents as defined earlier
}

interface BriefcaseFolderInterface {
  spaceId: string;
  parentId: string | null;
  title: string;
  dateCreated: string;
  deleted: boolean;
  id: string;
}
interface Props {
  folder: BriefcaseFolderInterface;
  spaceId: string;
}
export default function BriefcaseFolder({ folder, spaceId }: Props) {
  const [documents, setDocuments] = useState<BriefcaseDocuments[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const briefcaseFolders = useSelector(
    (state: RootState) => state.briefcase.briefcaseFolders.documents
  );
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [viewFolder, setViewFolder] = useState(true);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [rootDocs, setRootDocs] = useState<BriefcaseDocuments[]>([]);
  // const [folders, setFolders] =
  //   useState<BriefcaseGroupFolders[]>(briefcaseFolders);

  const briefcaseDocuments = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocuments.data
  );
  const draggedItem = useSelector(
    (state: RootState) => state.briefcase.draggedItem
  );

  // const [_, setDraggedItem] = useState<{
  //   item: BriefcaseDocuments;
  //   fromTable: string;
  // } | null>(null);

  //   UseEffect
  useEffect(() => {
    const spaceId = folder.spaceId;
    const groupId = folder.id;
    dispatch(getFolder({ spaceId, groupId }));
  }, []);

  useEffect(() => {
    briefcaseFolders.forEach((collection) => {
      if (collection.id === folder.id) setDocuments(collection.documents);
    });
  }, [briefcaseFolders, folder.id]);

  useEffect(() => {
    const filteredGroupDocs = briefcaseDocuments.filter((doc) => !doc.groupId);
    setRootDocs(filteredGroupDocs);
  }, []);

  //   Functions
  const handleOptionsClick = (index: number, action: string) => {
    if (action === 'remove') {
      setConfirmDelete(true);
    }
    if (action === 'rename') {
      return;
    }
  };
  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };
  const handleDeleteDocument = (index: number) => {
    const documentId = documents[index].documentId;
    setIsDeleteLoading(true);

    dispatch(removeBriefcaseFile({ spaceId, documentId })).then(({ type }) => {
      setIsDeleteLoading(false);
      if (type.includes('fulfilled')) {
        closeConfirmDelete();
        toast.success('Document deleted successfully');
        dispatch(getBriefcaseDocuments(spaceId));
      }
    });
    dispatch(getBriefcaseDocuments(spaceId)); //to update the briefcase files

    return;
  };
  const handleRemoveDocument = () => {
    handleDeleteDocument(fileIndex);
  };
  const handleFileUploadCancel = () => {
    setIsFileUploadModalOpen(false);
  };

  // For handling drag and drop feature
  // Drag handlers for rows
  const onDragStart = (
    event: React.DragEvent,
    item: BriefcaseDocuments,
    fromTable: string
  ) => {
    console.log('Drag start!!');
    dispatch(setDraggedItem({ item, fromTable }));
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    console.log('Dragged over!');
  };

  const onDrop = (event: React.DragEvent, toTable: string) => {
    console.log('Dropped!!');
    if (draggedItem) {
      // Prevent dropping into the same table
      if (draggedItem.fromTable === toTable) {
        return;
      }

      // (prevGroups) => {
      // Remove the item from the source table
      const updatedFolders = briefcaseFolders.map((folder) => {
        if (folder.id === draggedItem.fromTable) {
          return {
            ...folder,
            documents: folder.documents.filter(
              (i) => i.id !== draggedItem.item.id
            ),
          };
        }
        return folder;
      });

      console.log(updatedFolders);

      // Add the item to the target table
      const newUpdatedFolders = updatedFolders.map((folder) => {
        if (folder.id === toTable) {
          return {
            ...folder,
            documents: [...folder.documents, draggedItem.item],
          };
        }
        return folder;
      });

      console.log(newUpdatedFolders);
      dispatch(setFolders(newUpdatedFolders));

      // setFolders((prevGroups) => {
      //   // Remove the item from the source table
      //   const updatedTables = prevGroups.map((group) => {
      //     if (group.id === draggedItem.fromTable) {
      //       return {
      //         ...group,
      //         items: group.documents.filter(
      //           (i) => i.id !== draggedItem.item.id
      //         ),
      //       };
      //     }
      //     return group;
      //   });

      //   // Add the item to the target table
      //   return updatedTables.map((table) => {
      //     if (table.id === toTable) {
      //       return {
      //         ...table,
      //         items: [...table.documents, draggedItem.item],
      //       };
      //     }
      //     return table;
      //   });
      // });

      // Reset dragged item
      setDraggedItem(null);
    }
  };
  const items_section = [
    {
      key: '1',
      action: 'rename',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={rename} alt='rename file' className='w-5' />
          <p>Rename</p>
        </div>
      ),
    },
    {
      key: '2',
      action: 'remove',
      label: (
        <div className='flex gap-x-2 cursor-pointer pl-2 pr-5'>
          <img src={trash} alt='delete file' className='w-5' />
          <p>Remove</p>
        </div>
      ),
    },
  ];
  const items = [
    // {
    //   key: '1',
    //   action: 'details',
    //   label: (
    //     <div className='flex gap-x-2'>
    //       <img src={details} alt='create new folder' className='w-4' />
    //       <p>Details</p>
    //     </div>
    //   ),
    // },
    {
      key: '2',
      action: 'move',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={moveFile} alt='rename file' className='w-5' />
          <p>Move file</p>
        </div>
      ),
    },
    {
      key: '3',
      action: 'rename',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={rename} alt='rename file' className='w-5' />
          <p>Rename</p>
        </div>
      ),
    },
    {
      key: '1',
      action: 'access',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={access} alt='rename file' className='w-5' />
          <p>Access control</p>
        </div>
      ),
    },

    {
      key: '5',
      action: 'share',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={link} alt='share file' className='w-5' />
          <p>Share</p>
        </div>
      ),
    },

    {
      key: '6',
      action: 'remove',
      label: (
        <div className='flex gap-x-2 cursor-pointer pl-2 pr-5'>
          <img src={trash} alt='delete file' className='w-5' />
          <p>Remove</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      header: 'Name',
      field: 'name',
      body: (rowData: BriefcaseDocuments) => {
        return (
          <section
            className='w-[400px] cursor-pointer'
            onClick={() => {
              navigate(
                `/admin/mybriefcase/details/${spaceId}/${rowData.documentId}`
              );
            }}
          >
            <section className='flex items-center gap-x-4'>
              <div className='bg-gray-800 text-white text-xs rounded-lg overflow-hidden w-[36px] h-[36px] flex items-center justify-center'>
                <FileThumbnail
                  file={rowData}
                  height={36}
                  width={36}
                  imgTagheight={36}
                  imgTagwidth={36}
                />
              </div>
              <p className='text-black text-sm'>{rowData?.title}</p>
            </section>
          </section>
        );
      },
    },
    {
      header: 'Actions',
      field: 'actions',
      sortable: true,
      body: (rowData: BriefcaseDocuments) => {
        return (
          <section className='w-full flex gap-x-2'>
            <div className='flex gap-x-2'>
              <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-2 py-1 min-w-14 justify-center'>
                <img src={eyeIcon} alt='profile' className='w-4' />
                <div className='text-sm font-medium capitalize'>
                  <p className='text-xs'>{rowData.views}</p>
                </div>
              </div>
              <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-2 py-1 min-w-14 justify-center'>
                <img src={inboxIcon} alt='profile' className='w-4' />
                <div className='text-sm font-medium capitalize'>
                  <p className='text-xs'>{rowData.downloads}</p>
                </div>
              </div>
              <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-2 py-1 min-w-14 justify-center'>
                <img src={presentationIcon} alt='profile' className='w-4' />
                <div className='text-sm font-medium capitalize'>
                  <p className='text-xs'>{rowData.eventCount}</p>
                </div>
              </div>
            </div>
          </section>
        );
      },
    },

    {
      header: 'Permission',
      field: 'permission',
      body: (rowData: BriefcaseDocuments) => {
        return (
          <section className='flex gap-x-5 items-center'>
            <p className='text-sm'>Private</p>
            <IsPrivate
              isPrivate={rowData.isPrivate}
              spaceId={spaceId}
              documentId={rowData.documentId}
            />
          </section>
        );
      },
    },

    {
      header: '',
      sortable: true,
      body: (rowData: any, options: { rowIndex: number }) => {
        return (
          <div className='w-[100px] flex justify-end cursor-pointer'>
            <section onClick={(e) => e.preventDefault()}>
              <Dropdown
                trigger={['click']}
                dropdownRender={(menu) => (
                  <Menu>
                    {items.map((item) => {
                      return (
                        <Menu.Item
                          key={item.key}
                          onClick={() => {
                            setFileIndex(options.rowIndex);
                            handleOptionsClick(options.rowIndex, item.action);
                          }}
                        >
                          {item.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                )}
              >
                <div className='cursor-pointer py-1'>
                  <img src={ellipsis} alt='options' />
                </div>
              </Dropdown>
            </section>
          </div>
        );
      },
    },
  ];
  return (
    <section className='my-2 mt-3'>
      {/* {folder.title.toLowerCase() !== 'general' && ( */}
      <section className='bg-[#EEEEEE] text-[#333333] text-sm h-12 flex gap-x-3 items-center justify-between px-10 cursor-pointer rounded-md'>
        <div className='flex items-center gap-x-3'>
          <p className='font-semibold'>{folder.title}</p>
          <img src={sectionIcon} alt='section' />
        </div>
        <div className='flex items-center gap-x-3'>
          <div onClick={() => setViewFolder(!viewFolder)}>
            <img src={viewFolder ? arrowUp : arrowDown} alt='' />
          </div>
          <section onClick={(e) => e.preventDefault()}>
            <Dropdown
              trigger={['click']}
              dropdownRender={(menu) => (
                <Menu>
                  {items_section.map((item) => {
                    return (
                      <Menu.Item
                        key={item.key}
                        // onClick={() => {
                        //   setFileIndex(options.rowIndex);
                        //   handleOptionsClick(options.rowIndex, item.action);
                        // }}
                      >
                        {item.label}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              )}
            >
              <div className='cursor-pointer py-4'>
                <img src={ellipsis} alt='options' />
              </div>
            </Dropdown>
          </section>
        </div>
        {/* <div onClick={() => setViewFolder(!viewFolder)}>
          <img src={viewFolder ? arrowUp : arrowDown} alt='' />
        </div> */}
        {/* <div onClick={() => setIsFileUploadModalOpen(true)}>
            <i
              className='cursor-pointer pi pi-plus'
              style={{ fontSize: '1rem', paddingBottom: 0, marginBottom: 0 }}
            ></i>
          </div> */}
      </section>
      {/* )} */}
      {/* Table data */}
      {viewFolder && (
        <section>
          <DataTable
            globalFilterFields={['name', 'lastUpdated', 'tags']}
            value={documents}
            tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
            size={'normal'}
            emptyMessage={<></>}
            reorderableRows
            className='rounded-3xl'
            dataKey='id'
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, folder.id)}
          >
            <Column
              // rowReorder
              style={{ width: '3rem' }}
              header=' '
              // field=''
              headerClassName={`bg-[#F5F5F5F5] text-blue300 font-normal text-sm hidden`}
              body={(rowData: BriefcaseDocuments) => {
                return (
                  <div
                    className='cursor-pointer min-w-4'
                    draggable
                    onDragStart={(e) => {
                      onDragStart(e, rowData, folder.id);
                      console.log('Drag started');
                    }}
                  >
                    <img src={dragNdropIcon} alt='drag' className='w-4' />
                  </div>
                );
              }}
            />
            {columns.map((col, index) => {
              return (
                <Column
                  key={index}
                  body={col?.body}
                  field={col.field}
                  header={col.header}
                  headerClassName={`bg-[#F5F5F5F5] text-blue300 font-normal text-sm hidden`}
                  className='text-sm'
                />
              );
            })}
          </DataTable>
        </section>
      )}

      <Modal
        open={isFileUploadModalOpen}
        onCancel={handleFileUploadCancel}
        footer={null}
      >
        <UploadDocuments
          closeModal={handleFileUploadCancel}
          spaceId={spaceId}
          groupId={folder.id}
        />
      </Modal>

      <ConfirmationModal
        open={confirmDelete}
        isLoading={isDeleteLoading}
        onClose={closeConfirmDelete}
        func={handleRemoveDocument}
        text='Delete document'
        subText='Are you sure you want to delete this document?'
      />
    </section>
  );
}
