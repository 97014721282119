// Assets
import logo from 'assets/svg/logo-white-background.png';
import backgroundPattern from 'assets/svg/background-pattern.svg';

// Slices
import { getConnectionState, getSignalRConnection } from 'store/SignalR/signalRSlice';

export default function JoinSharedPresentation() {
  let connection = getSignalRConnection();

  const connectionState = getConnectionState();

  return (
    <section className='bg-[#F2F2F2] w-full min-h-[100vh] flex justify-center pt-20 pb-10'>
      <section className='w-[568px] bg-white rounded-xl pb-10'>
        <div className='relative'>
          <img
            src={backgroundPattern}
            alt='pattern'
            className='max-w-[398px] absolute top-0 left-[50%] -translate-x-[50%]'
          />
          <img
            src={logo}
            alt='logo'
            className='absolute top-10 left-[50%] -translate-x-[50%]'
          />
        </div>

        <div className='w-full flex justify-center mt-[170px]'>
          <h1 className='font-bold text-[32px] '>
            Enter your details to access the link
          </h1>
        </div>

        <form className='px-10 grid grid-cols-1 mt-5 gap-5'>
          <label htmlFor='fullname'>
            <p className='text-gray700 font-semibold'>
              Full name<span className='text-primary'>*</span>
            </p>
            <input
              type='text'
              id='fullname'
              placeholder='Enter your full name'
              className='bg-[#F6F3EF] px-3 py-3 w-full rounded-lg mt-2'
            />
          </label>
          <label htmlFor='email'>
            <p className='text-gray700 font-semibold'>
              Email address<span className='text-primary'>*</span>
            </p>
            <input
              type='text'
              id='email'
              placeholder='Enter your email address'
              className='bg-[#F6F3EF] px-3 py-3 w-full rounded-lg mt-2'
            />
          </label>
          <label htmlFor='fullname'>
            <p className='text-gray700 font-semibold'>
              Passcode<span className='text-primary'>*</span>
            </p>
            <input
              type='text'
              id='fullname'
              placeholder='Enter meeting passcode'
              className='bg-[#e9e4dd] px-3 py-3 w-full rounded-lg mt-2'
            />
          </label>
          <button className='bg-black text-white px-3 py-3 w-full rounded-lg mt-5 font-semibold'>
            view link
          </button>
        </form>
      </section>
    </section>
  );
}
