import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  _createBriefcase,
  _getBriefcases,
  _updateBriefcase,
  _getBriefcaseDocuments,
  _addBriefcaseFile,
  _setBriefcaseDocumentPermission,
  _removeBriefcase,
  _removeBriefcaseFile,
  _getBriefcase,
  _getBriefcasePresentationHistory,
  _getBriefcaseShareHistory,
  _getDocumentVisits,
  _shareNewBriefcasePresentation,
  _addPresentationDocuments,
  _getDocumentAnalytics,
  _getDocumentUsage,
  _getDocument,
  _createFolder,
  _getFolders,
  _getFolder,
} from 'services/Briefcase/briefcaseServices';
import { BriefcaseItemsType } from 'types/Admin/Briefcase/BriefcaseTypes';

interface DocumentShare {
  title: string;
  spaceId: string;
  startTime: string;
  expiry?: string;
  canDownload: number;
  type: number;
  openLink: boolean;
  emails: string[];
  domains: string[];
  passcode: string;
}

interface ShareHistory {
  id: string;
  spaceId: string;
  title: string;
  friendlyId: string;
  dateCreated: string; // ISO date string
  views: number;
  published: boolean;
}

interface BriefcaseDocument {
  id: string;
  parentId: string | null;
  folderId: string;
  url: string;
  title: string;
  note: string | null;
  slides: any[]; // Adjust this type depending on what the `slides` array contains, for now it's `any[]`
}

interface BriefcaseDocumentAnalytics {
  visits: number;
  presentationMinutes: number;
  downloads: number;
  links: number;
}
interface BriefcaseDocumentVisits {
  publicationId: string;
  publicationType: string | null;
  documentId: string;
  documentTitle: string;
  userId: string;
  date: string;
  spaceId: string | null;
  download: boolean;
  id: string;
}

interface AddBriefcaseFilePayload {
  formData: FormData;
  spaceId: string;
}
interface SetBriefcaseDocumentPermissionPayload {
  data: {
    isPrivate: boolean;
    documentId: string;
  };
  spaceId: string;
}

interface Document {
  documentId: string;
  documentTitle: string;
  position: number;
}

interface AddPresentationDocuments {
  publicationId: string;
  documents: Document[];
}
interface CreateFolder {
  spaceId: string;
  title: string;
  parentId: string | null;
}

interface GetFolder {
  spaceId: string;
  groupId: string;
}
interface BriefcaseGroupDocument {
  spaceId: string;
  documentId: string;
  groupId: string;
  parentGroup: string | null;
  group: string;
  isPrivate: boolean;
  title: string;
  url: string;
  views: number;
  downloads: number;
  eventCount: number;
  dateAdded: string; // ISO date string format
  dateModified: string; // ISO date string format
  id: string;
}

interface BriefcaseFolder {
  spaceId: string;
  parentId: string | null;
  title: string;
  dateCreated: string;
  deleted: boolean;
  id: string;
}
interface BriefcaseDocuments {
  spaceId: string;
  documentId: string;
  groupId: string;
  parentGroup: string | null;
  group: string;
  isPrivate: boolean;
  title: string;
  url: string;
  views: number;
  downloads: number;
  eventCount: number;
  dateAdded: string; // ISO date string format
  dateModified: string; // ISO date string format
  id: string;
}

interface BriefcaseGroupFolder {
  id: string;
  title: string;
  dateCreated: string; // ISO date string format
  subGroups: BriefcaseFolder[]; // Assuming subGroups is an array of similar BriefcaseFolder objects
  documents: BriefcaseGroupDocument[]; // Array of documents as defined earlier
}

interface DraggedItem {
  item: BriefcaseGroupDocument;
  fromTable: string;
}

interface BriefcaseDocumentUsage {
  createdBy: string;
  publicationType: number;
  dateCreated: string; // ISO date string
  download: boolean;
  downloads: number;
  averageAttentionScore: number;
  averageTimeSpent: number;
  views: number;
  eventId: string;
}

interface InitialState {
  briefcases: {
    isLoading: boolean;
    data: BriefcaseItemsType[];
  };
  briefcase: {
    isLoading: boolean;
    data: BriefcaseItemsType | null;
  };

  briefcaseDocument: {
    isLoading: boolean;
    data: BriefcaseDocument | null;
    visits: BriefcaseDocumentVisits[];
    analytics: BriefcaseDocumentAnalytics | null;
    usage: BriefcaseDocumentUsage[];
  };
  briefcaseDocuments: {
    isLoading: boolean;
    data: BriefcaseDocuments[];
  };
  briefcaseHistory: {
    isLoading: boolean;
    shareHistory: ShareHistory[];
    presentationHistory: any;
  };

  briefcaseFolders: {
    isLoading: boolean;
    folders: BriefcaseFolder[];
    documents: BriefcaseGroupFolder[];
  };
  draggedItem: DraggedItem | null;
}

const initialState: InitialState = {
  briefcases: {
    isLoading: false,
    data: [],
  },
  briefcase: {
    isLoading: false,
    data: null,
  },
  briefcaseDocument: {
    isLoading: false,
    data: null,
    visits: [],
    analytics: null,
    usage: [],
  },
  briefcaseDocuments: {
    isLoading: false,
    data: [],
  },
  briefcaseHistory: {
    isLoading: false,
    shareHistory: [],
    presentationHistory: null,
  },
  briefcaseFolders: {
    isLoading: false,
    folders: [],
    documents: [],
  },
  draggedItem: null,
};

//get
export const getBriefcases = createAsyncThunk(
  'briefcase/getBriefcases',
  async (teamId: string) => {
    const response = await _getBriefcases(teamId);
    return response.data;
  }
);

export const getShareHistory = createAsyncThunk(
  'briefcase/getShareHistory',
  async (spaceId: string) => {
    const response = await _getBriefcaseShareHistory(spaceId);
    return response.data;
  }
);

export const getPresentationHistory = createAsyncThunk(
  'briefcase/getPresentationHistory',
  async (spaceId: string) => {
    const response = await _getBriefcasePresentationHistory(spaceId);
    return response.data;
  }
);

export const getBriefcase = createAsyncThunk(
  'briefcase/getBriefcase',
  async ({ spaceId, teamId }: { spaceId: string; teamId: string }) => {
    const response = await _getBriefcase(spaceId, teamId);
    return response.data;
  }
);

export const getDocumentVisits = createAsyncThunk(
  'briefcase/getDocumentVisits',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _getDocumentVisits(spaceId, documentId);
    return response.data;
  }
);
export const getDocumentAnalytics = createAsyncThunk(
  'briefcase/getDocumentAnalytics',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _getDocumentAnalytics(spaceId, documentId);
    return response.data;
  }
);
export const getDocumentUsage = createAsyncThunk(
  'briefcase/getDocumentUsage',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _getDocumentUsage(spaceId, documentId);
    return response.data;
  }
);

export const getBriefcaseDocument = createAsyncThunk(
  'briefcase/getDocument',
  async (documentId: string) => {
    const response = await _getDocument(documentId);
    return response.data;
  }
);

export const getBriefcaseDocuments = createAsyncThunk(
  'briefcase/getBriefcaseDocuments',
  async (spaceId: string) => {
    const response = await _getBriefcaseDocuments(spaceId);
    return response.data;
  }
);

//POST
export const createBriefcase = createAsyncThunk(
  'briefcase/createBriefcase',
  async (data: FormData) => {
    const response = await _createBriefcase(data);
    return response.data;
  }
);

export const updateBriefcase = createAsyncThunk(
  'briefcase/updateBriefcase',
  async (data: FormData) => {
    const response = await _updateBriefcase(data);
    return response.data;
  }
);
export const addBriefcaseFile = createAsyncThunk(
  'briefcase/addBriefcaseFile',
  async ({ spaceId, formData }: AddBriefcaseFilePayload) => {
    const response = await _addBriefcaseFile(spaceId, formData);
    return response.data;
  }
);
export const setBriefcaseDocumentPermission = createAsyncThunk(
  'briefcase/setBriefcasePermission',
  async ({ spaceId, data }: SetBriefcaseDocumentPermissionPayload) => {
    const response = await _setBriefcaseDocumentPermission(spaceId, data);
    return response.data;
  }
);
export const removeBriefcase = createAsyncThunk(
  'briefcase/removeBriefcase',
  async ({ spaceId, archive }: { spaceId: string; archive: boolean }) => {
    const response = await _removeBriefcase(spaceId, archive);
    return response.data;
  }
);

export const removeBriefcaseFile = createAsyncThunk(
  'briefcase/removeBriefcaseFile',
  async ({ spaceId, documentId }: { spaceId: string; documentId: string }) => {
    const response = await _removeBriefcaseFile(spaceId, documentId);
    return response.data;
  }
);

export const shareNewPresentation = createAsyncThunk(
  'briefcase/shareNewPresentation',
  async (data: DocumentShare) => {
    const response = await _shareNewBriefcasePresentation(data);
    return response.data;
  }
);
export const addPresentationDocuments = createAsyncThunk(
  'briefcase/addPresentationDocuments',
  async (data: AddPresentationDocuments) => {
    const response = await _addPresentationDocuments(data);
    return response.data;
  }
);
export const createFolder = createAsyncThunk(
  'briefcase/createFolder',
  async ({ spaceId, title, parentId }: CreateFolder) => {
    const response = await _createFolder(spaceId, title, parentId);
    return response.data;
  }
);
export const getFolders = createAsyncThunk(
  'briefcase/getFolders',
  async (spaceId: string) => {
    const response = await _getFolders(spaceId);
    return response.data;
  }
);
export const getFolder = createAsyncThunk(
  'briefcase/getFolder',
  async ({ spaceId, groupId }: GetFolder) => {
    const response = await _getFolder(spaceId, groupId);
    return response.data;
  }
);

export const briefcaseSlice = createSlice({
  name: 'briefcase',
  initialState,
  reducers: {
    setFolders(state, action: PayloadAction<BriefcaseGroupFolder[]>) {
      state.briefcaseFolders.documents = action.payload;
    },
    setDraggedItem(state, action: PayloadAction<DraggedItem | null>) {
      state.draggedItem = action.payload;
    },
    // setBriefcaseDetailsAction(state, action: PayloadAction<number>) {
    //   if (state.briefcases.data) {
    //     state.briefcaseDetails.data = state.briefcases.data[action.payload];
    //   }
    // },
  },

  // (state, action: PayloadAction<number>) => {
  //   state.value += action.payload;
  // }
  extraReducers: (builder) => {
    builder
      .addCase(getBriefcases.pending, (state) => {
        state.briefcases.isLoading = true;
        state.briefcases.data = [];
      })
      .addCase(
        getBriefcases.fulfilled,
        (state, action: PayloadAction<BriefcaseItemsType[]>) => {
          state.briefcases.isLoading = false;
          state.briefcases.data = action.payload;
        }
      )
      .addCase(getBriefcases.rejected, (state) => {
        state.briefcases.isLoading = false;
      });

    // briefcase
    builder
      .addCase(getBriefcase.pending, (state) => {
        state.briefcase.isLoading = true;
        state.briefcase.data = null;
      })
      .addCase(
        getBriefcase.fulfilled,
        (state, action: PayloadAction<BriefcaseItemsType>) => {
          state.briefcase.isLoading = false;
          state.briefcase.data = action.payload;
        }
      )
      .addCase(getBriefcase.rejected, (state) => {
        state.briefcase.isLoading = false;
      });

    builder
      .addCase(getBriefcaseDocuments.pending, (state) => {
        state.briefcaseDocuments.isLoading = true;
        state.briefcaseDocuments.data = [];
      })
      .addCase(
        getBriefcaseDocuments.fulfilled,
        (state, action: PayloadAction<BriefcaseDocuments[]>) => {
          state.briefcaseDocuments.isLoading = false;
          state.briefcaseDocuments.data = action.payload;
        }
      )
      .addCase(getBriefcaseDocuments.rejected, (state) => {
        state.briefcaseDocuments.isLoading = false;
      });

    // getPresentationHistory
    builder
      .addCase(getPresentationHistory.pending, (state) => {
        state.briefcaseHistory.isLoading = true;
        state.briefcaseHistory.presentationHistory = [];
      })
      .addCase(
        getPresentationHistory.fulfilled,
        (state, action: PayloadAction<BriefcaseItemsType[]>) => {
          state.briefcaseHistory.isLoading = false;
          state.briefcaseHistory.presentationHistory = action.payload;
        }
      )
      .addCase(getPresentationHistory.rejected, (state) => {
        state.briefcaseHistory.isLoading = false;
      });

    // getShareHistory
    builder
      .addCase(getShareHistory.pending, (state) => {
        state.briefcaseHistory.isLoading = true;
        state.briefcaseHistory.shareHistory = [];
      })
      .addCase(
        getShareHistory.fulfilled,
        (state, action: PayloadAction<ShareHistory[]>) => {
          state.briefcaseHistory.isLoading = false;
          state.briefcaseHistory.shareHistory = action.payload;
        }
      )
      .addCase(getShareHistory.rejected, (state) => {
        state.briefcaseHistory.isLoading = false;
      });

    // getDocument
    builder
      .addCase(getBriefcaseDocument.pending, (state) => {
        state.briefcaseDocument.isLoading = true;
        state.briefcaseDocument.data = null;
      })
      .addCase(
        getBriefcaseDocument.fulfilled,
        (state, action: PayloadAction<BriefcaseDocument>) => {
          state.briefcaseDocument.isLoading = false;
          state.briefcaseDocument.data = action.payload;
        }
      )
      .addCase(getBriefcaseDocument.rejected, (state) => {
        state.briefcaseDocument.isLoading = false;
      });
    // get document visits
    builder
      .addCase(getDocumentVisits.pending, (state) => {
        state.briefcaseDocument.isLoading = true;
        state.briefcaseDocument.visits = [];
      })
      .addCase(
        getDocumentVisits.fulfilled,
        (state, action: PayloadAction<BriefcaseDocumentVisits[]>) => {
          state.briefcaseDocument.isLoading = false;
          state.briefcaseDocument.visits = action.payload;
        }
      )
      .addCase(getDocumentVisits.rejected, (state) => {
        state.briefcaseDocument.isLoading = false;
      });
    // get document analytics
    builder
      .addCase(getDocumentAnalytics.pending, (state) => {
        state.briefcaseDocument.isLoading = true;
        state.briefcaseDocument.analytics = null;
      })
      .addCase(
        getDocumentAnalytics.fulfilled,
        (state, action: PayloadAction<BriefcaseDocumentAnalytics>) => {
          state.briefcaseDocument.isLoading = false;
          state.briefcaseDocument.analytics = action.payload;
        }
      )
      .addCase(getDocumentAnalytics.rejected, (state) => {
        state.briefcaseDocument.isLoading = false;
      });
    // get document usage
    builder
      .addCase(getDocumentUsage.pending, (state) => {
        state.briefcaseDocument.isLoading = true;
        state.briefcaseDocument.usage = [];
      })
      .addCase(
        getDocumentUsage.fulfilled,
        (state, action: PayloadAction<BriefcaseDocumentUsage[]>) => {
          state.briefcaseDocument.isLoading = false;
          state.briefcaseDocument.usage = action.payload;
        }
      )
      .addCase(getDocumentUsage.rejected, (state) => {
        state.briefcaseDocument.isLoading = false;
      });

    // Get folders
    builder
      .addCase(getFolders.pending, (state) => {
        state.briefcaseFolders.isLoading = true;
        state.briefcaseFolders.folders = [];
      })
      .addCase(
        getFolders.fulfilled,
        (state, action: PayloadAction<BriefcaseFolder[]>) => {
          state.briefcaseFolders.isLoading = false;
          state.briefcaseFolders.folders = action.payload;

          //   const folders = action.payload;
          //   const index = folders.findIndex(
          //     (item) => item.title.toLowerCase() === 'general'
          //   );

          //   // If "general" is found, move it to the start
          //   if (index !== -1) {
          //     const [generalItem] = folders.splice(index, 1); // remove the item from current position
          //     folders.unshift(generalItem); // add the item to the start
          //   }
          //   state.briefcaseFolders.folders = folders;
        }
      )
      .addCase(getFolders.rejected, (state) => {
        state.briefcaseFolders.isLoading = false;
      });

    // Get folder
    builder
      .addCase(getFolder.pending, (state) => {
        // state.briefcaseFolders.isLoading = true;
        state.briefcaseFolders.documents = [
          ...state.briefcaseFolders.documents,
        ];
      })
      .addCase(
        getFolder.fulfilled,
        (state, action: PayloadAction<BriefcaseGroupFolder>) => {
          const existingDocuments = state.briefcaseFolders.documents;
          const newDocument = action.payload;

          // Find if a document with the same title already exists
          const existingIndex = existingDocuments.findIndex(
            (doc) => doc.title === newDocument.title
          );

          if (existingIndex !== -1) {
            // If it exists, update the document at that index
            existingDocuments[existingIndex] = newDocument;
          } else {
            // If it doesn't exist, add the new document to the list
            existingDocuments.push(newDocument);
          }

          // Update the state with the modified documents list
          state.briefcaseFolders.documents = existingDocuments;
        }
      )
      .addCase(getFolder.rejected, (state) => {
        // state.briefcaseFolders.isLoading = false;
      });
  },
});

// export const getBriefcaseState = (state) => state.briefcase;
export const {
  setFolders,
  setDraggedItem,
  // setBriefcaseDetailsAction,
  // clearteamMembers,
  // setSideImages,
  // clearSideImages,
  // setActiveSlide,
} = briefcaseSlice.actions;
export default briefcaseSlice.reducer;
