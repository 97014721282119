import { useEffect, useState } from 'react';

//assets
import logo from 'assets/svg/fullLogo.svg';
import Folder from './Folder';

// libraries
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

// interface
// import { DocCategory } from 'interfaces/SharedPresentation';

export default function Sidebar() {
  const presentation_ = useSelector(
    (state: RootState) => state.sharePresentation.presentation.data
  );
  const { doc } = presentation_ ?? {};

  return (
    <section className='h-screen bg-orange20 '>
      <div className='flex flex-col justify-between h-full px-4 py-4'>
        <section>
          {/* Logo */}
          <div className=''>
            <img src={logo} alt='Presenta Logo' className='w-24' />
          </div>
          {/* Folders */}
          <section className='mb-2 mt-4'>
            {doc && doc.map((doc_) => <Folder doc={doc_} />)}
          </section>
        </section>
      </div>
    </section>
  );
}
