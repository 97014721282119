import Axios from 'lib/axiosInterceptor';

//get

export const _getPresentation = (id: string) => {
  return Axios.get(`/Presentation/${id}`);
};

// Post

export const _requestSharedAccess = () => {
  return Axios.post('/hubs/PresenterService/RequestSharedAccess');
};
