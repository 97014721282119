// import { Avatar } from 'antd';
import helerose from 'assets/image/helenrose.png';
import { Avatar } from 'primereact/avatar';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getInitials } from 'utils/formatter';

export default function AddMembers({
  isAddMemeberModalOpen,
  setIsAddMemberModalOpen,
}: {
  isAddMemeberModalOpen: boolean;
  setIsAddMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const briefcaseMembers = useSelector(
    (state: RootState) => state.briefcase.briefcase.data?.members
  );
  return (
    <>
      {isAddMemeberModalOpen && (
        <section className='z-[1000]'>
          {/* <section> */}
          {/* wrapper */}
          <section
            className='fixed top-0 left-0 w-full h-full bg-black opacity-20 z-[1001] cursor-default'
            onClick={() => {
              console.log(isAddMemeberModalOpen);
              setIsAddMemberModalOpen(false);
              console.log(isAddMemeberModalOpen);
              console.log('Wrapper is clickable');
            }}
          ></section>
          <section className=''>
            {/* <div className='w-[318px] bg-white p-3 rounded-md border absolute top-10 left-0 z-[1002]'> */}
            <div
              className={`w-[318px] bg-white p-3 rounded-md border absolute top-10 left-0 z-[1002] --- transition-all duration-500 ease-linear ${
                isAddMemeberModalOpen
                  ? 'opacity-100 scale-100'
                  : 'opacity-0 scale-0'
              }`}
            >
              <div className='w-full border rounded-md grid grid-cols-1'>
                {briefcaseMembers &&
                  briefcaseMembers?.length > 0 &&
                  briefcaseMembers?.map((member, index) => (
                    <div className='px-2 pt-2 '>
                      <div className='flex items-center gap-x-2'>
                        <input type='checkbox' className='cursor-pointer' />
                        {/* <img
                          src={helerose}
                          alt='profile'
                          className='h-9 w-9 rounded-full'
                        /> */}
                        <Avatar
                          label={getInitials(member.fullName)}
                          shape='circle'
                          size='normal'
                          key={index}
                        />
                        <div>
                          <h4>{member.fullName}</h4>
                          <p className='text-sm'>{}</p>
                        </div>
                      </div>
                      <hr className='mt-2' />
                    </div>
                  ))}

                {/* <div className='px-2 pt-2 '>
                  <div className='flex items-center gap-x-2'>
                    <input type='checkbox' />
                    <img
                      src={helerose}
                      alt='profile'
                      className='h-9 w-9 rounded-full'
                    />
                    <div>
                      <h4>Helen rose</h4>
                      <p className='text-sm'>helerose@gmail.com</p>
                    </div>
                  </div>
                  <hr className='mt-2' />
                </div> */}
                {/* <div className='px-2 pt-2 '>
                  <div className='flex items-center gap-x-2'>
                    <input type='checkbox' />
                    <img
                      src={helerose}
                      alt='profile'
                      className='h-9 w-9 rounded-full'
                    />
                    <div>
                      <h4>Helen rose</h4>
                      <p className='text-sm'>helerose@gmail.com</p>
                    </div>
                  </div>
                </div> */}
              </div>
              <button className='bg-black text-white h-12 w-full rounded-md mt-2'>
                Save
              </button>
            </div>
          </section>
        </section>
      )}
    </>
  );
}
