import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as signalR from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { baseUrl } from "utils/baseUrl";
import { getToken } from "utils/localStorageServices";
import { errorToast } from "utils/toastUtils";

let connection = null; // Hold the connection object outside the Redux store

const initialState = {
  connectionId: null,
  isConnected: false,
  connectionStatus: null,
};

export const connectSignalR = createAsyncThunk(
  "signalR/connect",
  async (_, { dispatch }) => {
    connection = new signalR.HubConnectionBuilder()
      .withUrl(`${baseUrl}/pubsub/present`, {
        accessTokenFactory: () => {
          return `${getToken()}`;
        },
      })
      // .withHubProtocol(new MessagePackHubProtocol())
      .withAutomaticReconnect()
      .build();

    // Handle connection state changes and errors
    connection.onreconnecting((error) => {
      dispatch(setReconnecting());
      console.error("SignalR reconnecting due to error: ", error);
    });

    connection.onreconnected((connectionId) => {
      dispatch(setReconnected());
      console.log("SignalR reconnected. Connection ID: ", connectionId);
    });

    connection.onclose((error) => {
      if (!error) return;
      dispatch(setDisconnected(error ? error.message : "Connection closed"));
      console.error("SignalR connection closed due to error: ", error);
    });
    try {
      dispatch(setConnecting());
      await connection.start();
      return connection.connectionId;
    } catch (error) {
      dispatch(setDisconnected(error.message));

      // throw new Error(`Failed to connect to SignalR: ${error.message}`);
    }
  }
);

export const disconnectSignalR = createAsyncThunk(
  "signalR/disconnect",
  async (_, { dispatch }) => {
    if (connection) {
      try {
        // Remove all handlers to prevent memory leaks
        connection.off("onreconnecting");
        connection.off("onreconnected");
        connection.off("onclose");

        // Attempt to stop the SignalR connection
        await connection.stop();
        dispatch(resetConnection());
        console.log("SignalR connection successfully stopped.");
      } catch (error) {
        console.error("Error while disconnecting SignalR: ", error.message);
        dispatch(setDisconnected(error.message));
      } finally {
        // Reset the connection object to avoid reuse
        connection = null;
      }
    } else {
      dispatch(setDisconnected("Connection is already disconnected."));
      console.warn("Attempted to disconnect an inactive SignalR connection.");
    }
  }
);
export const signalRSlice = createSlice({
  name: "signalR",
  initialState,
  reducers: {
    resetConnection: (state) => {
      state.connectionStatus = null;
    },
    setConnecting: (state) => {
      state.connectionStatus = "connecting";
    },
    setReconnecting: (state) => {
      state.connectionStatus = "reconnecting";
    },
    setReconnected: (state) => {
      state.connectionStatus = "connected";
      state.error = null;
    },

    setDisconnected: (state, { payload }) => {
      state.connectionStatus = "disconnected";
      state.error = payload;
      errorToast(`SignalR connection closed with error: "${payload || ""}"`);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(connectSignalR.fulfilled, (state, { payload }) => {
        state.connectionId = payload;
        state.isConnected = true;
        state.connectionStatus = "connected";
        // toast.success("SignalR connected");
      })
      .addCase(connectSignalR.rejected, (state, { error }) => {
        state.connectionStatus = "disconnected";
        errorToast(error?.message);
      })
      .addCase(disconnectSignalR.fulfilled, (state) => {
        state.isConnected = false;
        state.connectionStatus = "disconnected";
      });
  },
});

export const getSignalRState = (state) => state.signalR;

export const {
  setReconnecting,
  setReconnected,
  setDisconnected,
  setConnecting,
  resetConnection,
} = signalRSlice.actions;

export const getSignalRConnection = () => connection;
export const getConnectionState = () => connection?.state;
export default signalRSlice.reducer;
