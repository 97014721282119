import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//components
import Loader from 'components/UI/Loader';
import Navbar from './Navbar';

//libraries
import { AppDispatch, RootState } from 'store/store';
import { Outlet, useNavigate } from 'react-router-dom';

//slices
import { getBriefcases } from 'store/Briefcase/briefcaseSlice';
import { getTeamInfo, getTeamsState } from 'store/Teams/teamsSlice';
import EmptyBriefcase from 'components/Admin/BriefCase/EmptyBriefcase';

export default function BriefcaseLayout() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  //state & store
  const { teamInfo } = useSelector(getTeamsState);
  const briefcases = useSelector(
    (state: RootState) => state.briefcase.briefcases
  );
  const tab = useSelector((state: RootState) => state.briefcaseTab.tab);

  //useeffects
  useEffect(() => {
    dispatch(getTeamInfo()).then((response) => {
      console.log(response);
      if (!response.payload) {
        console.log('One');
      } else {
        console.log('two');
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const teamId = teamInfo.data?.id;
    // const teamId = teamInfo.data?.teamId;

    teamId && dispatch(getBriefcases(teamId));
  }, [teamInfo, dispatch, navigate]);
  return (
    <section className='relative'>
      {/* loader */}
      {(briefcases.isLoading || teamInfo.isLoading) && <Loader />}
      {!briefcases.isLoading && !teamInfo.isLoading && tab === 0 && (
        <section>
          <Navbar />
          <section className='mt-20 min-h-fit'>
            {(briefcases.data?.length < 1 ||
              (!teamInfo.isLoading && !teamInfo.data)) && <EmptyBriefcase />}
            {briefcases.data?.length > 0 && <Outlet />}
          </section>
        </section>
      )}
    </section>
  );
}
