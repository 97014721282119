import { useEffect, useState } from 'react';

//assets
// import linkOrange from 'assets/svg/link-orange.svg';
// import lockCircle from 'assets/svg/lock-circle.svg';
// import send from 'assets/svg/send-2.svg';
// import lockCircleOrange from 'assets/svg/lock-circle-orange.svg';
// import sendOrange from 'assets/svg/send-2-orange.svg';
import uploadBulk from 'assets/svg/people2.svg';
import sms from 'assets/svg/sms-tracking.svg';
import calender from 'assets/svg/calendar-edit.svg';
import timer from 'assets/svg/timer3.svg';
// import cirlceI from 'assets/svg/circle_i.svg';
// import x_icon from 'assets/svg/x.svg';
import arrowRight from 'assets/svg/arrow-right-white.svg';
// import arrowLeft from 'assets/svg/arrow-left-orange.svg';
import linkSquare from 'assets/svg/link-square.svg';
import previewImg from 'assets/image/previewFile.png';
import previewIcon from 'assets/svg/preview-icon.svg';
import copy from 'assets/svg/link2.svg';
import copyImage from 'assets/image/copy-image.png';
import presentationChart from 'assets/svg/presention-chart.svg';
import copyIcon from 'assets/svg/copy-1.svg';

// components
// import FileThumbnail from 'components/Admin/BriefCase/FileThumbnail';

// Libraries
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
import { DatePicker } from 'antd';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import {
  shareNewPresentation,
  addPresentationDocuments,
} from 'store/Briefcase/briefcaseSlice';
import { toast } from 'sonner';
// import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

// Extend dayjs with UTC plugin
dayjs.extend(utc);

//Types
interface Props {
  spaceId: string;
  presentationType: number;
  schedulePresentation: boolean;
}

interface BriefcaseDocuments {
  spaceId: string;
  documentId: string;
  groupId: string | null;
  isPrivate: boolean;
  title: string;
  url: string;
  views: number;
  downloads: number;
  eventCount: number;
  dateAdded: string; // You can use `Date` type if you want to work with JavaScript Date objects.
  dateModified: string; // Same as above.
  id: string;
}

interface DocumentShare {
  title: string;
  spaceId: string;
  startTime: string; // ISO 8601 date-time string
  expiry?: string; // ISO 8601 date-time string
  canDownload: number;
  type: number;
  openLink: boolean;
  emails: string[];
  domains: string[];
  passcode: string;
}

interface Document {
  documentId: string;
  documentTitle: string;
  position: number;
}

interface AddPresentationDocuments {
  publicationId: string;
  documents: Document[];
}

export default function ShareBriefcase({
  spaceId,
  presentationType,
  schedulePresentation,
}: Props) {
  //
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // store
  const briefcaseDocuments = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocuments.data
  );

  // states
  const [tab, setTab] = useState(0);
  const [emailName, setEmailName] = useState<string>('');
  const [domainName, setDomainName] = useState<string>('');
  const [selectedFiles, setSelectedFiles] =
    useState<BriefcaseDocuments[]>(briefcaseDocuments);
  const [usePasscode, setUsePasscode] = useState(false);
  const [linkExpires, setLinkExpires] = useState(false);
  const [shouldRestrictDomains, setShouldRestrictDomains] = useState(false);
  const [shareLinkItems, setShareLinkItems] = useState<DocumentShare>({
    title: '',
    spaceId: spaceId,
    startTime: '',
    expiry: '',
    canDownload: 0,
    type: presentationType,
    openLink: false,
    emails: [],
    domains: [],
    passcode: '',
  });
  const [previewLinkId, setPreviewLinkId] = useState('');
  const [friendlyId, setFriendlyId] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState<any>();
  const [routeOrigin, setRouteOrigin] = useState(window.location.origin);
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  // const briefcaseFolders = useSelector(
  //   (state: RootState) => state.briefcase.briefcaseFolders.folders
  // );
  // const currentFolderDocs = useSelector(
  //   (state: RootState) => state.briefcase.briefcaseFolders.documents
  // );
  // useEffect(() => {
  //   const filteredDocs = briefcaseDocuments.filter(
  //     (doc) => doc.isPrivate !== true
  //   );
  //   // setFilteredIsPrivateDocument(filteredDocs);
  //   setSelectedFiles(filteredDocs);
  // }, []);

  useEffect(() => {
    const time = dayjs() as any;
    setCurrentTime(time);
    const currentTime_ = dayjs.utc(time).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    setShareLinkItems({ ...shareLinkItems, startTime: currentTime_ });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeEmail = (index: number) => {
    const updatedEmails = shareLinkItems.emails.filter((_, i) => i !== index);
    setShareLinkItems({ ...shareLinkItems, emails: updatedEmails });
  };

  const removeDomain = (index: number) => {
    const updatedDomains = shareLinkItems.domains.filter((_, i) => i !== index);
    setShareLinkItems({ ...shareLinkItems, domains: updatedDomains });
  };

  const handleKeyDownEmails = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (email === ',') {
        setEmailName('');
        return;
      }
      if (email) {
        setShareLinkItems({
          ...shareLinkItems,
          emails: [...shareLinkItems.emails, email],
        });
      }
      setEmailName('');
    }
  };

  const copyLink = async () => {
    let routeOrigin = window.location.origin;
    let presentationLink = `${routeOrigin}/${
      presentationType === 1 ? 'presentation-details' : 'shared-briefcase'
    }/${friendlyId}`;

    try {
      await navigator.clipboard.writeText(presentationLink);
      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  const handleKeyDownDomains = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const domain = (e.target as HTMLInputElement).value.trim();
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      if (domain === ',') {
        setDomainName('');
        return;
      }
      if (domain) {
        setShareLinkItems({
          ...shareLinkItems,
          domains: [...shareLinkItems.domains, domain],
        });
      }
      setDomainName('');
    }
  };

  // const onOkStartTime = (value: string) => {
  //   const selectedDate = dayjs.utc(value); // Convert selected date string to dayjs object in UTC
  //   const formattedDate = selectedDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); //this formats the time to  ISO 8601 date-time string
  //   setShareLinkItems({
  //     ...shareLinkItems,
  //     startTime: formattedDate,
  //   });
  // };

  const onOkExpiry = (value: string) => {
    const selectedDate = dayjs.utc(value); // Convert selected date string to dayjs object in UTC
    const formattedDate = selectedDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    setShareLinkItems({
      ...shareLinkItems,
      expiry: formattedDate,
    });
  };

  const handleShareBriefcaseUserInputs = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    setShareLinkItems({ ...shareLinkItems, [name]: value });
  };

  const handleShareBriefcase = async () => {
    setIsLoading(true);
    const ShareLinkItemscopy = { ...shareLinkItems };
    if (ShareLinkItemscopy.expiry === '') {
      delete ShareLinkItemscopy.expiry;
    }

    const { type, payload } = await dispatch(
      shareNewPresentation(ShareLinkItemscopy)
    );

    if (!type.includes('fulfilled')) {
      toast.error('Presentation was not created!');
      return;
    } else {
      setTab(1);
      setPreviewLinkId(payload.id);
      setFriendlyId(payload.friendlyId);
      toast.success('Presentation created successfully');
    }
    setShareLinkItems({
      title: '',
      spaceId: spaceId,
      startTime: '',
      expiry: '',
      canDownload: 0,
      type: presentationType,
      openLink: false,
      emails: [],
      domains: [],
      passcode: '',
    });
  };

  const handlePresentBriefcase = async () => {
    setIsLoading(true);
    const ShareLinkItemscopy = { ...shareLinkItems };
    if (ShareLinkItemscopy.expiry === '') {
      delete ShareLinkItemscopy.expiry;
    }

    const { type, payload } = await dispatch(
      shareNewPresentation(ShareLinkItemscopy)
    );

    if (!type.includes('fulfilled')) {
      toast.error('Presentation was not created!');
      return;
    }

    if (payload) {
      setPreviewLinkId(payload.id);
      setFriendlyId(payload.friendlyId);
      setIsLoading(false);
      toast.success('Presentation created successfully');
      setTab(1);

      // const presentationDocumments: AddPresentationDocuments = {
      //   publicationId: payload.id,
      //   documents: selectedFiles
      //     .filter((doc) => doc.isPrivate === false)
      //     .map((doc, index) => {
      //       return {
      //         documentId: doc.documentId,
      //         documentTitle: doc.title,
      //         position: index,
      //       };
      //     }),
      // };

      // dispatch(addPresentationDocuments(presentationDocumments)).then(
      //   ({ type }) => {
      //     if (type.includes('fulfilled')) {
      //       setIsLoading(false);
      //       toast.success('Presentation created successfully');
      //       setTab(1);
      //     } else {
      //       toast.error('Presentation was not created!');
      //       setIsLoading(false);
      //     }
      //   }
      // );
    } else {
      setIsLoading(false);
      toast.error('Presentation was not created!');
    }

    // Reseting the inputs and tabs
    setShareLinkItems({
      title: '',
      spaceId: spaceId,
      startTime: '',
      expiry: '',
      canDownload: 0,
      type: presentationType,
      openLink: false,
      emails: [],
      domains: [],
      passcode: '',
    });
  };

  // const columns = [
  //   {
  //     header: 'Mark all',
  //     field: 'name',
  //     body: (rowData: BriefcaseDocuments) => {
  //       return (
  //         <section className='w-[400px] cursor-pointer'>
  //           <section className='flex items-center gap-x-4'>
  //             <div className='bg-gray-800 text-white text-xs rounded-lg overflow-hidden w-[36px] h-[36px] flex items-center justify-center'>
  //               <FileThumbnail
  //                 file={rowData}
  //                 height={36}
  //                 width={36}
  //                 imgTagheight={36}
  //                 imgTagwidth={36}
  //               />
  //             </div>
  //             <p className='text-black text-sm'>{rowData?.title}</p>
  //           </section>
  //         </section>
  //       );
  //     },
  //   },
  // ];

  return (
    <section className='pt-5 pb-20 min-h-[100vh] relative'>
      <div className='w-full border-b pb-2'>
        <h1 className='text-xl font-medium'>
          {presentationType === 0 ? 'Share link' : 'Presentation'}
        </h1>
      </div>
      {/* Indicators */}
      {/* <section className=''>
        <div className='flex items-center mt-3 px-4 w-full pt-3 pb-10 border-b border-t '>
          <div className='min-w-10 min-h-10 flex items-center justify-center border border-[#EAECF0] rounded-lg shadow-sm relative'>
            <img src={linkOrange} alt='link' />
            <div className='absolute -bottom-8 left-[50%] -translate-x-[50%] w-[70px]'>
              <p className='text-sm font-semibold'>Create link</p>
            </div>
          </div>
          <hr className={`w-full ${tab !== 0 && 'border border-primary '}`} />
          <div className='min-w-10 min-h-10 flex items-center justify-center border border-[#EAECF0] rounded-lg shadow-sm relative'>
            <img src={tab !== 0 ? lockCircleOrange : lockCircle} alt='link' />
            <div className='absolute -bottom-8 left-[50%] -translate-x-[50%] w-[130px]'>
              <p className='text-sm text-[#333333]'>Manage permissions</p>
            </div>
          </div>
          <hr className={`w-full ${tab === 2 && 'border border-primary '}`} />
          <div className='min-w-10 min-h-10 flex items-center justify-center border border-[#EAECF0] rounded-lg shadow-sm relative'>
            <img src={tab !== 2 ? send : sendOrange} alt='link' />
            <div className='absolute -bottom-8 left-[50%] -translate-x-[50%] w-[95px]'>
              <p className='text-sm text-[#333333]'>Share your link</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* First Tab */}
      {tab === 0 && (
        <section className=''>
          <div className='flex flex-col gap-y-1 mt-5'>
            <label htmlFor='space' className='font-normal text-gray700'>
              Enter Presentation name
            </label>
            <input
              type='text'
              name='title'
              required
              value={shareLinkItems?.title || ''}
              placeholder='e.g for external board members'
              className='py-3 pl-4 text-sm rounded-lg outline-none bg-orange10'
              onChange={(e) => {
                handleShareBriefcaseUserInputs(e);
                if (e.target.value.trim().length > 0) {
                  setDisableButton(false);
                } else {
                  setDisableButton(true);
                }
              }}
            />
          </div>
          {/* share link */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <div className='w-full flex justify-between items-center mt-3'>
              <p className='text-base'>Share link with anyone</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input
                  type='checkbox'
                  // value={shareLinkItems.openLink}
                  checked={shareLinkItems.openLink}
                  className='sr-only peer'
                  onChange={() => {
                    setShareLinkItems({
                      ...shareLinkItems,
                      openLink: !shareLinkItems.openLink,
                    });
                  }}
                />
                <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
            {/* add email */}
            <div className='grid grid-cols-9 gap-x-2 mt-3'>
              <label
                htmlFor='add-email'
                className='col-span-6 rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
              >
                <img src={sms} alt='email' className='w-4 h-4' />
                <input
                  type='text'
                  id='add-email'
                  placeholder='add email'
                  className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                  onKeyDown={handleKeyDownEmails}
                  value={emailName || ''}
                  onChange={(e) => setEmailName(e.target.value)}
                />
              </label>
              <button className='flex gap-x-2 items-center px-3 py-2 col-span-3 w-full bg-[#F5F5F5] rounded-md'>
                <img src={uploadBulk} alt='upload' className='h-4 w-4' />
                <p className='text-sm text-[#6B6B6B]'>upload bulk</p>
              </button>
            </div>
            {/* email... */}
            <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
              {shareLinkItems.emails?.map((email, index) => {
                return (
                  <div
                    key={index}
                    className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                  >
                    <p>{email}</p>
                    <i
                      className='text-xs cursor-pointer pi pi-times mt-[2px]'
                      onClick={() => removeEmail(index)}
                    ></i>
                  </div>
                );
              })}
            </section>
          </section>

          {/* use password */}
          {!presentationType && (
            <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
              <div className='w-full flex justify-between items-center mt-3'>
                <p className='text-base'>Use password</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    checked={usePasscode}
                    onChange={() => {
                      if (usePasscode) {
                        setShareLinkItems({
                          ...shareLinkItems,
                          passcode: '',
                        });
                        setUsePasscode(false);
                      } else {
                        setUsePasscode(true);
                      }
                    }}
                    className='sr-only peer'
                  />
                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
              {/* enter password */}
              {usePasscode && (
                <div className='w-full mt-3'>
                  <label
                    htmlFor='add-email'
                    className='w-full rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
                  >
                    <img src={sms} alt='email' className='w-4 h-4' />
                    <input
                      type='text'
                      id='add-email'
                      placeholder='Enter password'
                      className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                      name='passcode'
                      value={shareLinkItems.passcode || ''}
                      onChange={(e) => {
                        setUsePasscode(true);
                        handleShareBriefcaseUserInputs(e);
                      }}
                    />
                  </label>
                </div>
              )}
            </section>
          )}

          {/* Link time */}
          {!presentationType && (
            <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
              {/* Start time */}
              {/* <div className=''>
              <p className='text-base'>Start time</p>

              <div className='w-full mt-3 grid grid-cols-2 gap-x-2'>
                <div
                  className={`col-span-1 bg-[#F5F5F5] w-full py- rounded-md px flex items-center gap-x-2`}
                >
                  <DatePicker
                    showTime
                    onOk={onOkStartTime}
                    minDate={dayjs()}
                    // defaultValue={currentTime.toString()}
                    // defaultValue={moment()}
                    defaultValue={currentTime}
                    suffixIcon={
                      <img src={calender} alt='calender' className='w-4 h-4' />
                    }
                    format='YYYY-MM-DD'
                    className='bg-[#F5F5F5] w-full h-full border-none outline-none'
                  />
                </div>
                <div className='col-span-1 bg-[#F5F5F5] w-full py-3 rounded-md px-3 flex items-center gap-x-2'>
                  <img src={timer} alt='timer' />
                  <p className='text-sm text-[#6B6B6B]'>
                    {shareLinkItems.startTime === ''
                      ? '0:00 AM'
                      : dayjs(shareLinkItems.startTime).format('h:mm A')}
                  </p>
                </div>
              </div>
            </div> */}

              <>
                {/* link expires */}
                <div className='w-full flex justify-between items-center'>
                  <p className='text-base'>Set link expiry date</p>
                  <label className='inline-flex items-center cursor-pointer'>
                    <input
                      type='checkbox'
                      checked={linkExpires}
                      className='sr-only peer'
                      onChange={() => {
                        if (linkExpires) {
                          setLinkExpires(false);
                          setShareLinkItems({ ...shareLinkItems, expiry: '' });
                        } else {
                          setLinkExpires(true);
                        }
                      }}
                    />
                    <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                  </label>
                </div>
                {/* expires */}
                {linkExpires && (
                  <div className='w-full mt-3 grid grid-cols-2 gap-x-2'>
                    <div
                      className={`col-span-1 bg-[#F5F5F5] w-full py- rounded-md px flex items-center gap-x-2 ${
                        !linkExpires && 'pointer-events-none'
                      }`}
                    >
                      <DatePicker
                        showTime
                        onOk={onOkExpiry}
                        minDate={dayjs()}
                        // onChange={onChangeExpiry}
                        suffixIcon={
                          <img
                            src={calender}
                            alt='calender'
                            className='w-4 h-4'
                          />
                        }
                        format='YYYY-MM-DD'
                        className='bg-[#F5F5F5] w-full h-full border-none outline-none'
                      />
                    </div>
                    <div className='col-span-1 bg-[#F5F5F5] w-full py-3 rounded-md px-3 flex items-center gap-x-2'>
                      <img src={timer} alt='timer' />
                      <p className='text-sm text-[#6B6B6B]'>
                        {shareLinkItems.expiry === ''
                          ? '0:00 AM'
                          : dayjs(shareLinkItems.expiry).format('h:mm A')}
                      </p>
                    </div>
                  </div>
                )}
              </>
            </section>
          )}

          {/* Allow downloads */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <div className='w-full flex justify-between items-center'>
              <p className='text-base'>Allow downloads</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input
                  type='checkbox'
                  onChange={() => {
                    setShareLinkItems({
                      ...shareLinkItems,
                      canDownload: shareLinkItems.canDownload === 0 ? 1 : 0,
                    });
                  }}
                  className='sr-only peer'
                />

                <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
          </section>

          {/* More security */}
          <section className='border rounded-xl border-[#EEEEEE] p-3 mt-3'>
            <h2 className='text-base font-semibold'>More security</h2>
            <div className='w-full flex justify-between items-center mt-3'>
              <p className='text-base'>Restrict domain</p>
              <label className='inline-flex items-center cursor-pointer'>
                <input
                  type='checkbox'
                  checked={shouldRestrictDomains}
                  onChange={() => {
                    if (shouldRestrictDomains) {
                      setShouldRestrictDomains(false);
                    } else {
                      setShouldRestrictDomains(true);
                    }
                  }}
                  className='sr-only peer'
                />
                <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
              </label>
            </div>
            {/* add domain */}
            <div className='grid grid-cols-9 gap-x-2 mt-3'>
              <label
                htmlFor='add-domain'
                className='col-span-6 rounded-md overflow-hidden flex bg-[#F5F5F5] pl-3 items-center'
              >
                <img src={sms} alt='domain' className='w-4 h-4' />
                <input
                  type='text'
                  id='add-email'
                  placeholder='Add domain'
                  className='py-3 pl-1 pr-3 text-sm rounded-lg outline-none bg-[#F5F5F5] w-full'
                  value={domainName}
                  onKeyDown={handleKeyDownDomains}
                  onChange={(e) => setDomainName(e.target.value)}
                />
              </label>
              <button className='flex gap-x-2 items-center px-3 py-2 col-span-3 w-full bg-[#F5F5F5] rounded-md'>
                <img src={uploadBulk} alt='upload' className='h-4 w-4' />
                <p className='text-sm text-[#6B6B6B]'>upload bulk</p>
              </button>
            </div>
            {/* email... */}
            <section className='flex flex-wrap my-2 gap-y-2 gap-x-3'>
              {shareLinkItems.domains?.map((domain, index) => {
                return (
                  <div
                    key={index}
                    className='flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180'
                  >
                    <p>{domain}</p>
                    <i
                      className='text-xs cursor-pointer pi pi-times mt-[2px]'
                      onClick={() => removeDomain(index)}
                    ></i>
                  </div>
                );
              })}
            </section>
          </section>
        </section>
      )}

      {/* Third Tab */}
      {tab === 1 && (
        <section>
          <section className='p-3 bg-[#E6F2ED] h-20 flex gap-x-4 rounded-xl items-center '>
            <img src={linkSquare} alt='review!' className='w-6' />
            <div className=''>
              <p className='text-sm text-black font-semibold'>
                Link created successfully
              </p>
              {/* <p className='cursor-pointer'>{`${routeOrigin}/presentation-details/${friendlyId}`}</p> */}
              <p className='cursor-pointer' onClick={copyLink}>
                {`${routeOrigin}/${
                  presentationType === 1
                    ? 'presentation-details'
                    : 'shared-briefcase'
                }/${friendlyId}`}{' '}
                <span className='mr-1'>
                  <img src={copyIcon} alt='copy' className='cursor-pointer' />
                </span>
              </p>
            </div>
          </section>

          {presentationType === 0 ? (
            <section className='rounded-md border p-3 flex flex-col gap-y-2 mt-5'>
              <img src={previewImg} alt='preview file' className='w-[83px]' />
              <p>See how your file will look like to the public</p>
              <button
                className='flex gap-x-2 bg-[#F5F5F5] py-2 px-5 rounded-md w-fit'
                onClick={() => {
                  navigate(`/shared-briefcase/${previewLinkId}`);
                }}
              >
                <img src={previewIcon} alt='preveiw botton' />
                <p className='text-black'>Preview file</p>
              </button>
            </section>
          ) : (
            <section className='rounded-md border p-3 flex flex-col gap-y-2 mt-5'>
              <img src={previewImg} alt='preview file' className='w-[83px]' />
              <p>Edit your presentation and share</p>
              <button
                className='flex gap-x-2 bg-[#F5F5F5] py-2 px-5 rounded-md w-fit'
                onClick={() => {
                  navigate(`/presentation-details/${previewLinkId}`);
                }}
              >
                <img src={presentationChart} alt='preveiw botton' />
                <p className='text-black'>Go to presentation</p>
              </button>
            </section>
          )}

          {/* <section className='rounded-md border p-3 flex flex-col gap-y-2 mt-5'>
            <img src={copyImage} alt='preview file' className='w-[57px]' />
            <p>Copy to your clipboard</p>
            <button
              className='flex gap-x-2 bg-[#F5F5F5] py-2 px-5 rounded-md w-fit'
              onClick={copyLink}
            >
              <img src={copy} alt='copy botton' />
              <p className='text-black'>Copy link</p>
            </button>
          </section> */}
        </section>
      )}

      {/* Next and previous */}
      <section className='absolute bottom-0 right-0 w-full border-y h-[66px] flex justify-end px-5 items-center'>
        {tab === 0 && (
          // <button
          //   className={`text-white h-10 px-5 flex gap-x-2 items-center justify-center rounded-md ${
          //     disableButton ? 'bg-orange-300' : 'bg-primary'
          //   }`}
          //   onClick={() => {
          //     setTab(1);
          //     // selectedFiles.length < 1
          //     //   ? setDisableButton(true)
          //     //   : setDisableButton(false);
          //   }}
          //   disabled={disableButton}
          // >
          //   <p className='font-semibold'>Share link</p>
          //   <img src={arrowRight} alt='next' />
          // </button>
          <button
            className={`text-white h-10 px-5 flex gap-x-2 items-center justify-center rounded-md min-w-[100px] 
             ${disableButton ? 'bg-orange-300' : 'bg-primary'}
             `}
            onClick={() => {
              if (presentationType) {
                handlePresentBriefcase();
              } else {
                handleShareBriefcase();
              }
            }}
            disabled={disableButton}
          >
            {!isLoading ? (
              <span className='flex items-center gap-x-2'>
                <p className='font-semibold'>Share link</p>
                <img src={arrowRight} alt='next' />
              </span>
            ) : (
              <ClipLoader size={12} color='white' />
            )}
          </button>
        )}
        {/* {tab === 1 && (
          <div className='flex gap-x-4'>
            <button
              className='bg-white border border-primary rounded-md text-primary h-10 px-5 flex gap-x-2 items-center justify-center'
              onClick={() => {
                setTab(0);
                shareLinkItems.title.length < 1
                  ? setDisableButton(true)
                  : setDisableButton(false);
              }}
            >
              <img src={arrowLeft} alt='previous' />
              <p className='font-semibold'>Previous</p>
            </button>
            <button
              className={`text-white h-10 px-5 flex gap-x-2 items-center justify-center rounded-md min-w-[100px] 
                ${disableButton ? 'bg-orange-300' : 'bg-primary'}
                `}
              onClick={() => {
                handleShareBriefcase();
              }}
              disabled={disableButton}
            >
              {!isLoading ? (
                <span className='flex items-center gap-x-2'>
                  <p className='font-semibold'>Create link</p>
                  <img src={arrowRight} alt='next' />
                </span>
              ) : (
                <ClipLoader size={12} color='white' />
              )}
            </button>
          </div>
        )} */}
      </section>
    </section>
  );
}

// {/* Second Tab */}
// {tab === 1 && (
//   <section className='px-5'>
//     <div className='p-3 bg-[#333333] h-20 flex gap-x-4 justify-between items-center'>
//       <img src={cirlceI} alt='review!' className='w-6' />
//       <p className='text-white text-sm'>
//         Review the files you would love to share before creating a link
//       </p>
//       <img src={x_icon} alt='close' className='w-4' />
//     </div>

//     {/* Table */}
//     {/* {briefcaseFolders.length > 0 &&
//       briefcaseFolders
//         .filter(
//           (folder) => folder.title.toLocaleLowerCase() !== 'generall'
//         )
//         .map((folder) => (
//           <section>
//             <DataTable
//               value={folder}
//               tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
//               size={'normal'}
//               selectionMode='checkbox'
//               selection={selectedFiles}
//               onSelectionChange={(e) => {
//                 setSelectedFiles(e.value);
//                 e.value.length < 1
//                   ? setDisableButton(true)
//                   : setDisableButton(false);
//               }}
//               dataKey='id'
//               className='border rounded-xl'
//             >
//               <Column
//                 selectionMode='multiple'
//                 headerStyle={{ width: '3rem' }}
//               />
//               {columns.map((col, index) => {
//                 return (
//                   <Column
//                     key={index}
//                     body={col?.body}
//                     field={col.field}
//                     header={col.header}
//                     headerClassName='text-blue300 font-normal text-sm'
//                     className='text-sm'
//                   />
//                 );
//               })}
//             </DataTable>
//           </section>
//         ))} */}
//     <section className='overflow-hidden rounded-xl w-full mt-5 bg-[#F9F7F4]'>
//       <DataTable
//         value={filteredIsPrivateDocuments}
//         tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
//         size={'normal'}
//         selectionMode='checkbox'
//         selection={selectedFiles}
//         onSelectionChange={(e) => {
//           setSelectedFiles(e.value);
//           e.value.length < 1
//             ? setDisableButton(true)
//             : setDisableButton(false);
//         }}
//         dataKey='id'
//         className='border rounded-xl'
//       >
//         <Column
//           selectionMode='multiple'
//           headerStyle={{ width: '3rem' }}
//         />
//         {columns.map((col, index) => {
//           return (
//             <Column
//               key={index}
//               body={col?.body}
//               field={col.field}
//               header={col.header}
//               headerClassName='text-blue300 font-normal text-sm'
//               className='text-sm'
//             />
//           );
//         })}
//       </DataTable>
//     </section>
//   </section>
// )}
