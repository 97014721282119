import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

//libraries

//assets
import empty from "assets/svg/emptyPresentation.svg";
import notFound from "assets/svg/presentation-notfound.svg";

//utils
import { navProps } from "./data";

//slices
import {
  getAllPresentations,
  getPresentationState,
} from "store/Presentations/presentationSlice";

//components
import Loader from "components/UI/Loader";
import AdminNavbar from "components/UI/AdminLayout/Navbar";
// import EmptyState from "components/Admin/Workspace/ContentPage/EmptyState";
import NavigationTab from "components/Admin/Presentation/PresentationDetails/NavigationTab";
import PresentationCard from "components/Admin/Presentation/Cards/PresentationCard";

export default function Presentation() {
  const dispatch = useDispatch();
  const { presentations } = useSelector(getPresentationState);

  //useref
  const searchParam = useRef();

  //usestates

  const [tab, setTab] = useState(
    localStorage.getItem("presentationTabActive")
      ? +localStorage.getItem("presentationTabActive")
      : 1
  );
  const [allPresentations, setAllPresentations] = useState([]);
  const [grouppedPresentations, setGrouppedPresentations] = useState([]);
  const [scheduledPresentation, setScheduledPresentation] = useState([]);
  const [onGoingPresentation, setOnGoingPresentation] = useState([]);
  const [pastPresentation, setPastPresentation] = useState([]);
  const [originalScheduledPresentation, setOriginalScheduledPresentation] =
    useState([]);
  const [originalOnGoingPresentation, setOriginalOnGoingPresentation] =
    useState([]);
  const [originalPastPresentation, setOriginalPastPresentation] = useState([]);

  //useeffect
  useEffect(() => {
    dispatch(getAllPresentations());
  }, [dispatch]);

  useEffect(() => {
    // const sortedPresentation =  presentations?.data?.sort((a,b)=>{
    //   if(a?.)
    // })
    setGrouppedPresentations(
      presentations?.data?.filter((presentation) => {
        if (tab === 2) {
          return presentation?.status === tab || presentation?.status === 3;
        }
        return presentation?.status === tab;
      })
    );

    // New splitting of data
    const scheduledPresentation = presentations?.data?.filter(
      (presentation) => {
        return presentation?.status === 1;
      }
    );
    setScheduledPresentation(scheduledPresentation);
    setOriginalScheduledPresentation(scheduledPresentation);

    //Past presentation

    const pastPresentation = presentations?.data?.filter((presentation) => {
      return presentation?.status === 4;
    });

    setPastPresentation(pastPresentation);
    setOriginalPastPresentation(pastPresentation);

    //Ongoing presentation
    const onGoingPresntation = presentations?.data?.filter((presentation) => {
      return presentation?.status === 2 || presentation?.status === 3;
    });
    setOnGoingPresentation(onGoingPresntation);
    setOriginalOnGoingPresentation(onGoingPresntation);

    setAllPresentations(presentations?.data);
  }, [presentations]);

  //functions
  const searchPresentation = (e) => {
    const searchedTitle = e.target?.value?.toLowerCase();

    searchParam.current = searchedTitle;
    // const filteredPresentations = presentations?.data?.filter(
    //   (presentation) => {
    //     const title = presentation?.title?.toLowerCase();
    //     return title?.includes(searchedTitle);
    //   }
    // );

    if (tab == 1) {
      if (searchedTitle) {
        setScheduledPresentation([
          ...originalScheduledPresentation.filter((item) =>
            item.title.toLowerCase().includes(searchedTitle)
          ),
        ]);
      } else {
        setScheduledPresentation([...originalScheduledPresentation]);
      }
    } else if (tab == 2) {
      if (searchedTitle) {
        setOnGoingPresentation([
          ...originalOnGoingPresentation.filter((item) =>
            item.title.toLowerCase().includes(searchedTitle)
          ),
        ]);
      } else {
        setOnGoingPresentation([...originalOnGoingPresentation]);
      }
    } else if (tab == 4) {
      if (searchedTitle) {
        setPastPresentation([
          ...originalPastPresentation.filter((item) =>
            item.title.toLowerCase().includes(searchedTitle)
          ),
        ]);
      } else {
        setPastPresentation([...originalPastPresentation]);
      }
    }

    // setAllPresentations(filteredPresentations);
  };

  useEffect(() => {}, [tab]);

  return (
    <section>
      <section className="relative">
        <AdminNavbar navProps={navProps} />
      </section>

      <section className="mt-20">
        <NavigationTab
          tab={tab}
          setTab={setTab}
          searchPresentation={searchPresentation}
        />
        {/* loader */}
        {presentations?.isLoading && <Loader />}
        {/*  */}
        {!presentations?.isLoading && (
          <>
            <section className="flex flex-col justify-center w-full pt-10 pb-20">
              <div className="flex items-center justify-center w-full">
                {((grouppedPresentations?.length < 1 && tab !== "all") ||
                  allPresentations?.length < 1) && (
                  <div className="h-[60vh] flex  justify-center items-center w-full ">
                    {searchParam.current ? (
                      <p className="text-2xl font-medium text-center">
                        {/* No Presentation found */}
                        <img src={notFound} alt="empty " className="" />
                      </p>
                    ) : (
                      <img src={empty} alt="empty " className="" />
                    )}
                  </div>
                )}
              </div>
              <section className="grid w-full grid-cols-1 lg:gap-x-8 lg:gap-y-8 gap-y-10 lg:grid-cols-3 md:grid-cols-2">
                {/* all presentations  */}

                {/* groupped presentations  */}
                {/* {grouppedPresentations?.length > 0 &&
                  grouppedPresentations?.map((presentation, index) => {
                    return (
                      <PresentationCard
                        presentation={presentation}
                        key={index}
                      />
                    );
                  })} */}

                {tab == 1 ? (
                  scheduledPresentation?.length ? (
                    scheduledPresentation.map((item) => {
                      return (
                        <PresentationCard
                          type="scheduled"
                          presentation={item}
                          key={item.id}
                        />
                      );
                    })
                  ) : (
                    <div>No Scheduled presentation</div>
                  )
                ) : tab == 2 ? (
                  onGoingPresentation?.length ? (
                    onGoingPresentation.map((item) => {
                      return (
                        <PresentationCard
                          type="ongoing"
                          presentation={item}
                          key={item.id}
                        />
                      );
                    })
                  ) : (
                    <div>No Ongoing Presentation </div>
                  )
                ) : tab == 4 ? (
                  pastPresentation?.length ? (
                    pastPresentation.map((item) => {
                      return (
                        <PresentationCard presentation={item} key={item.id} />
                      );
                    })
                  ) : (
                    <div>No Presentations from the past</div>
                  )
                ) : (
                  ""
                )}
              </section>
            </section>
          </>
        )}
      </section>
    </section>
  );
}
