import { useRef, useState } from 'react';

// Assets
import bell from 'assets/svg/bell.svg';
import sendIcon from 'assets/svg/send-icon.svg';
import ChevronOrange from 'assets/svg/chevron-down-orange.svg';
import menu from 'assets/svg/hamburger.svg';
import ellipsisVertical from 'assets/svg/ellipsis-vertical.svg';
import play from 'assets/svg/play2.svg';
import calender from 'assets/svg/calendar-2.svg';
import eyeI from 'assets/svg/eye-2.svg';
import chevronDownWhite from 'assets/svg/chevron-down-white.svg';
import addCircle from 'assets/svg/add-circle.svg';
import arrowRight from 'assets/svg/arrowRight.svg';
import playBtn from 'assets/svg/playBTNgray.svg';
import calender2 from 'assets/svg/calendar-2.svg';

// components
import CreateSpace from 'views/Admin/Briefcase/Modals/CreateBriefcase';

// Libraries
import { Drawer, Dropdown, Menu, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ShareBriefcase from 'views/Admin/Briefcase/Modals/ShareBriefcase';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { shareNewPresentation } from 'store/Briefcase/briefcaseSlice';
import { toast } from 'sonner';

// Store
import { addPresentationDocuments } from 'store/Briefcase/briefcaseSlice';
import dayjs from 'dayjs';

interface CreateSpaceModalRef {
  resetModal: () => void;
}

interface Document {
  documentId: string;
  documentTitle: string;
  position: number;
}
interface AddPresentationDocuments {
  publicationId: string;
  documents: Document[];
}

export default function Navbar() {
  const dispatch = useDispatch<AppDispatch>();

  // ref
  const createSpaceModal = useRef<CreateSpaceModalRef>(null);
  const briefcase = useSelector(
    (state: RootState) => state.briefcase.briefcase.data
  );
  const briefcaseDocument = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocument.data
  );
  const briefcaseDocuments = useSelector(
    (state: RootState) => state.briefcase.briefcaseDocuments.data
  );

  const [presentationType, setPresentationType] = useState(0); // 0 for share and 1 for presentation

  //
  const navigate = useNavigate();
  const { spaceId, fileId } = useParams();

  //state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openShareBriefcaseDrawer, setShareBriefCaseDrawer] = useState(false);
  const [schedulePresentation, setSchedulePresentation] =
    useState<boolean>(false);
  // const [friendlyId, setFriendlyId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  //function
  const openCreateSpaceModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    createSpaceModal.current?.resetModal();
  };

  const onClose = () => {
    setShareBriefCaseDrawer(false);
  };

  // Handles presenting briefcase now
  const handlePresentBriefcaseNow = async () => {
    // for formatting the presentation name
    const time = dayjs() as any;
    const presentationTimeName = dayjs.utc(time).format('YYYY-MM-DD');
    const presentationStartTime = dayjs
      .utc(time)
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    setIsLoading(true);
    const ShareLinkItemscopy = {
      title: `Presentation-${presentationTimeName}`,
      spaceId: spaceId ?? '',
      startTime: presentationStartTime,
      canDownload: 0,
      type: 1,
      openLink: false,
      emails: [],
      domains: [],
      passcode: '',
    };

    const { payload, type } = await dispatch(
      shareNewPresentation(ShareLinkItemscopy)
    );

    if (!type.includes('fulfilled')) {
      toast.error('Presentation was not created!');
      return;
    }

    if (payload) {
      // setFriendlyId(payload.friendlyId);
      let friendlyId = payload.friendlyId;
      setIsLoading(false);
      toast.success('Presentation created successfully');

      let presentationLink = `/presentation-details/${friendlyId}`;
      navigate(presentationLink);



      // const presentationDocumments: AddPresentationDocuments = {
      //   publicationId: payload.id,
      //   documents: briefcaseDocuments
      //     .filter((doc) => doc.isPrivate === false)
      //     .map((doc, index) => {
      //       return {
      //         documentId: doc.documentId,
      //         documentTitle: doc.title,
      //         position: index,
      //       };
      //     }),
      // };

      // dispatch(addPresentationDocuments(presentationDocumments)).then(
      //   ({ type }) => {
      //     if (type.includes('fulfilled')) {
      //       setIsLoading(false);
      //       toast.success('Presentation created successfully');

      //       // navigating to the presentation
      //       // let routeOrigin = window.location.origin;
      //       let presentationLink = `/presentation-details/${friendlyId}`;

      //       navigate(presentationLink);
      //     } else {
      //       toast.error('Presentation was not created!');
      //       setIsLoading(false);
      //     }
      //   }
      // );
    } else {
      setIsLoading(false);
      toast.error('Something went wrong');
    }
  };

  // birefcase file variables
  const items_ = [
    {
      key: '1',
      label: (
        <div className='flex gap-x-2'>
          <img src={play} alt='rename file' className='w-4' />
          <p>Create presentation</p>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div className='flex gap-x-2'>
          <img src={calender} alt='metrics file' className='w-4' />
          <p>Share file</p>
        </div>
      ),
    },
  ];

  //variables
  const items_PresentBriefcase = [
    {
      key: '1',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => {
            // setPresentationType(1);
            handlePresentBriefcaseNow();
            // setSchedulePresentation(false);
          }}
        >
          <img src={playBtn} alt='rename file' className='w-4' />
          <p>Present now</p>
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div
          className='flex gap-x-2'
          onClick={() => {
            setPresentationType(1);
            setSchedulePresentation(true);
            setShareBriefCaseDrawer(true);
          }}
        >
          <img src={calender2} alt='metrics file' className='w-4' />
          <p>Schedule presentation</p>
        </div>
      ),
    },
  ];

  return (
    <nav className='fixed top-0  lg:left-[18%] left-0 z-30 lg:w-[82%] w-full h-20 bg-white pt-4'>
      <section className='w-[95%] mx-auto'>
        <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
          <section className='flex items-center justify-between w-full lg:w-[30%]'>
            <img src={menu} alt='open sidenav' className='block lg:hidden' />

            <div className='leading-tight text-right lg:text-left justify-items-end justify-self-end lg:self-start'>
              <h1 className='text-lg font-bold lg:text-2xl font-grotesk'>
                Briefcase
              </h1>
              {/* nav details or breadcrumb */}
              <>
                {!spaceId && !fileId && (
                  <p className='lg:text-sm text-xs mt-[-3px]'>
                    Create a briefcase to manage your events and files
                  </p>
                )}
                {spaceId && !fileId && briefcase?.title && (
                  <div className='flex items-center mt-[-3px] cursor-pointer'>
                    <p
                      className='lg:text-sm text-xs'
                      onClick={() => {
                        navigate(`/admin/mybriefcase`);
                      }}
                    >
                      Briefcase
                    </p>
                    <img src={arrowRight} alt='next' className='w-3' />
                    <p
                      className='lg:text-sm text-xs'
                      onClick={() => {
                        navigate(`/admin/mybriefcase/details/${spaceId}`);
                      }}
                    >
                      {briefcase?.title}
                    </p>
                  </div>
                )}

                {spaceId &&
                  fileId &&
                  briefcase?.title &&
                  briefcaseDocument?.title && (
                    <div className='flex items-center mt-[-3px]'>
                      <p
                        className='lg:text-sm text-xs cursor-pointer'
                        onClick={() => {
                          navigate(`/admin/mybriefcase`);
                        }}
                      >
                        Briefcase
                      </p>
                      <img src={arrowRight} alt='next' className='w-3' />
                      <p
                        className='lg:text-sm text-xs cursor-pointer'
                        onClick={() => {
                          navigate(`/admin/mybriefcase/details/${spaceId}`);
                        }}
                      >
                        {briefcase?.title}
                      </p>
                      <img src={arrowRight} alt='next' className='w-3' />
                      <p className='lg:text-sm text-xs '>
                        {briefcaseDocument?.title}
                      </p>
                    </div>
                  )}
              </>
            </div>
          </section>

          {/* All briefcases list NOTE this does not account for user without briefcase*/}
          {!spaceId && !fileId && (
            <section className='flex gap-x-2'>
              <div
                className='bg-[#FF6929] px-2 py-2 text-white rounded-md text-sm flex gap-x-2 items-center cursor-pointer'
                onClick={() => {
                  openCreateSpaceModal();
                }}
              >
                <img src={addCircle} alt='add new briefcase' />
                <p>Create new briefcase</p>
              </div>
              <div className='flex items-center justify-between  flex-col lg:flex-row gap-y-4'>
                <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
                  <img src={bell} alt='notification' className='w-4' />
                </div>
              </div>
            </section>
          )}

          {/* one single briefcase */}
          {spaceId && !fileId && (
            <div className='flex items-center flex-col lg:flex-row gap-y-4 gap-x-2 text-sm font-medium'>
              <div
                className='h-8 flex items-center gap-x-2 px-5 rounded-md border border-[#AFAFAF] text-sm text-[#545454] cursor-pointer'
                onClick={() => {
                  navigate(`/briefcase-preview/${spaceId}`);
                }}
              >
                <p>Preview</p>
                <img src={eyeI} alt='eye' className='w-4' />
              </div>
              {/* Share briefcase */}
              <div
                className='px-3 border border-[#AFAFAF] rounded-md text-[#545454] flex gap-x-2 items-center h-8 cursor-pointer'
                onClick={() => {
                  setPresentationType(0);
                  setShareBriefCaseDrawer(true);
                }}
              >
                <p>Share briefcase</p>
                <img src={sendIcon} alt='share briefcase' />
              </div>

              {/* Present briefcase */}
              <section onClick={(e) => e.preventDefault()}>
                <Dropdown
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <Menu>
                      {items_PresentBriefcase.map((item) => {
                        return (
                          <Menu.Item key={item.key}>{item.label}</Menu.Item>
                        );
                      })}
                    </Menu>
                  )}
                >
                  <div className='px-3 text-[#FF6929] border border-[#D45722] rounded-md flex gap-x-2 items-center h-8 cursor-pointer'>
                    <p>Present briefcase</p>
                    <img src={ChevronOrange} alt='drop down' />
                  </div>
                </Dropdown>
              </section>

              <div className='px-2 border rounded-md cursor-pointer bg-gray20 h-8 flex items-center'>
                <img src={bell} alt='notification' className='w-4' />
              </div>
            </div>
          )}

          {/* briefcase file */}
          {spaceId && fileId && (
            <section className='gap-x-2 lg:flex hidden items-center justify-between'>
              {/* btn */}
              <div className='h-8 rounded-md w-8 border border-[#E2E2E2] cursor-pointer flex items-center justify-center'>
                <img src={ellipsisVertical} alt='ellipsis icon' />
              </div>
              <div className='h-8 flex items-center gap-x-2 px-5 rounded-md border border-[#AFAFAF] text-sm text-[#545454]'>
                <p>Preview</p>
                <img src={eyeI} alt='eye' className='w-4' />
              </div>
              <section onClick={(e) => e.preventDefault()}>
                <Dropdown
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <Menu>
                      {items_.map((item) => {
                        return (
                          <Menu.Item key={item.key}>{item.label}</Menu.Item>
                        );
                      })}
                    </Menu>
                  )}
                >
                  <div className='h-8 bg-black text-white flex items-center gap-x-2 px-5 rounded-md text-sm'>
                    <p>Create link</p>
                    <img src={chevronDownWhite} alt='eye' className='' />
                  </div>
                </Dropdown>
              </section>

              <div className='px-2 py-2 border rounded-md cursor-pointer bg-gray20'>
                <img src={bell} alt='notification' className='w-4' />
              </div>
            </section>
          )}
        </div>
      </section>
      {/* Modal */}
      <Modal
        footer={null}
        centered={false}
        open={isModalOpen}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
      >
        <CreateSpace closeModal={handleCancel} ref={createSpaceModal} />
      </Modal>

      {briefcase?.id && (
        <Drawer
          // title="Basic Drawer"
          placement='right'
          closable={false}
          onClose={onClose}
          open={openShareBriefcaseDrawer}
          width={468}
        >
          <ShareBriefcase
            spaceId={briefcase.id}
            presentationType={presentationType}
            schedulePresentation={schedulePresentation}
          />
        </Drawer>
      )}
    </nav>
  );
}
