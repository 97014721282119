import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { _getPresentation } from 'services/sharedPresentations/sharedPresentationsService';
import {
  InitialState,
  PresentationPayload,
  Document,
  DocCategory,
} from 'interfaces/SharedPresentation';

const initialState: InitialState = {
  presentation: {
    isLoading: true,
    data: null,
  },
  displayDocument: null,
  flattedDisplayDocuments: [],
  displayDocIndex: 0,
};

export const getPresentation = createAsyncThunk(
  'presentations/getPresentation',
  async (id: string) => {
    const response = await _getPresentation(id);
    return response.data;
  }
);

export const sharedPresentationSlice = createSlice({
  name: 'presentations',
  initialState,
  reducers: {
    setNextDisplayDoc(state) {
      if (state.displayDocIndex === state.flattedDisplayDocuments.length - 1) {
        state.displayDocIndex = 0;
      } else {
        state.displayDocIndex = state.displayDocIndex + 1;
      }
    },
    setPrevDisplayDoc(state) {
      // Function to handle Previous click
      if (state.displayDocIndex === 0) {
        state.displayDocIndex = state.flattedDisplayDocuments.length - 1;
      } else {
        state.displayDocIndex = state.displayDocIndex - 1;
      }
      // ? documents.length - 1 : prevIndex - 1
      //   );
      // };
    },
    setDisplayDoc(state, action: PayloadAction<string>) {
      const doc = state.flattedDisplayDocuments.find(
        (doc, index) => doc.id === action.payload
      );
      if (doc) {
        state.displayDocument = doc;
        const docIndex = state.flattedDisplayDocuments.findIndex(
          (doc_) => doc.id === doc_.id
        );
        state.displayDocIndex = docIndex;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPresentation.pending, (state) => {
        state.presentation.isLoading = true;
        state.presentation.data = null;
      })
      .addCase(
        getPresentation.fulfilled,
        (state, action: PayloadAction<PresentationPayload>) => {
          state.presentation.isLoading = false;
          // let displayDoc;
          if (action.payload.doc) {
            state.displayDocument = action.payload.doc[0].docs[0];
          }
          state.presentation.data = action.payload;

          //////////////////////////////
          // Helper function to recursively extract all documents, including sub-documents
          const extractAllDocuments = (
            categories: DocCategory[]
          ): Document[] => {
            const docs = categories.flatMap((category) => {
              // Combine the documents in the current category with those in its subcategories
              const allSubDocs =
                category.subs.length > 0
                  ? extractAllDocuments(category.subs)
                  : [];
              return [...category.docs, ...allSubDocs];
            });

            return docs;
          };
          state.flattedDisplayDocuments = extractAllDocuments(
            action.payload.doc
          );
          // console.log(documents);
        }
      )
      .addCase(getPresentation.rejected, (state) => {
        state.presentation.isLoading = false;
      });
  },
});

export default sharedPresentationSlice.reducer;
export const { setNextDisplayDoc, setPrevDisplayDoc, setDisplayDoc } =
  sharedPresentationSlice.actions;
