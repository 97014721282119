import React, { useEffect, useState } from 'react';

//utils
import { imageStream } from 'utils/imageStream';

//assets
import pdfImg from 'assets/image/pdf.png';
import pptImg from 'assets/image/ppt.png';
import excelImg from 'assets/image/excel.png';
import folderImg from 'assets/svg/folderImg.svg';

//libraries
import { ClipLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';

interface DocumentData {
  documentId?: string;
  downloads?: number;
  groupId?: string | null;
  id?: string;
  isPrivate?: boolean;
  spaceId?: string;
  title?: string;
  url?: string;
  views?: number;
  bannerId?: string;
}

interface Props {
  file: DocumentData;
  width: number;
  height: number;
  imgTagheight: number;
  imgTagwidth: number;
  // documentId: string;
}

export default function FileThumbnail({
  file,
  width,
  height,
  imgTagheight,
  imgTagwidth,
}: // documentId,
Props) {
  const dispatch = useDispatch();

  //usestate
  const [thumbnail, setThumbnail] = useState<string | null>(null);

  useEffect(() => {
    let isCancelled = false;

    if (!file) {
      setThumbnail(folderImg);
      return;
    }

    const fileUrl = file?.url;
    let objectUrl: string | undefined = undefined;

    const fetchImageStream = async () => {
      /**
       * For fetching the banner
       * Review these the conditional below can be better handled
       */
      setThumbnail(null);
      let url;
      if (file.bannerId) {
        url = await imageStream(file.bannerId, true, width, height);
        objectUrl = url;
      } else if (file.documentId) {
        url = await imageStream(file.documentId, true, width, height);
        objectUrl = url;
      } else if (file.id) {
        url = await imageStream(file.id, true, width, height);
        objectUrl = url;
      }

      if (!isCancelled && url) {
        setThumbnail(url);
      }
    };

    //handles spaces banner, since it doesn't return the document url
    if (!fileUrl && file.bannerId) {
      fetchImageStream();
    }

    // handles documents with both url and bannerid
    if (fileUrl) {
      if (fileUrl.endsWith('.ppt') || fileUrl.endsWith('.pptx')) {
        setThumbnail(pptImg);
      } else if (fileUrl.endsWith('.csv') || fileUrl.endsWith('.xlsx')) {
        setThumbnail(excelImg);
      } else if (fileUrl.endsWith('.pdf')) {
        setThumbnail(pdfImg);
      } else if (
        fileUrl.endsWith('.png') ||
        fileUrl.endsWith('.jpeg') ||
        fileUrl.endsWith('.jpg') ||
        fileUrl.endsWith('.svg')
      ) {
        fetchImageStream();
      }
    } else {
      setThumbnail(folderImg);
    }

    return () => {
      isCancelled = true;
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [file, dispatch, height, width]);

  return (
    <div className='flex items-center'>
      {thumbnail ? (
        <div
          className={`w-[${imgTagwidth}px] h-[${imgTagheight}px] overflow-hidden`}
        >
          <img
            src={thumbnail}
            alt={file?.title}
            className={`rounded-lg object-cover object-center max-h-[${imgTagwidth}px] max-w-[${imgTagwidth}px]`}
          />
        </div>
      ) : (
        <div
          className={`w-[${width ?? '36'}px] h-[${
            height ?? '36'
          }px] rounded-lg cursor-pointer flex justify-center items-center`}
        >
          <ClipLoader size={12} color='gray' />
        </div>
      )}
    </div>
  );
}
