import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import logo from "assets/svg/fullLogo.svg";
import instance from "lib/axiosInterceptor";
import PresentationCard from "components/Admin/Presentation/Cards/PresentationCard";
import { PresentationDataInterface } from "interfaces/interfaces-data";
import Loader from "components/UI/Loader";
import empty from "assets/svg/emptyPresentation.svg";
import notFound from "assets/svg/presentation-notfound.svg";

export const ExPresentations = () => {
  const [presentations, setPresentations] = useState<
    PresentationDataInterface[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const getPresentations = async () => {
    try {
      const result = await instance.get("/presentation/all");
      setPresentations(result.data);
    } catch (e) {
      setError("Unexpected Error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPresentations();
  }, []);
  return (
    <Row justify={"center"} className="bg-[#F2F2F2] pt-10 min-h-screen">
      <Col lg={14} xs={22}>
        <div>
          <span>
            <img src={logo} />
          </span>
          <div className="mb-4">
            <p className="font-grotesk text-2xl font-semibold mt-8">
              My Presentations
            </p>
            <p>
              You have{" "}
              {presentations.filter((item) => item.status !== 4).length}{" "}
              presentations
            </p>
          </div>
          <Row gutter={12}>
            {loading ? (
              <Col xs={24}>
                <Loader />
              </Col>
            ) : error ? (
              <Col xs={24}>
                <div className="flex justify-center">
                  Unexpected Error Occured! Try Again.
                </div>
              </Col>
            ) : !presentations.length ? (
              <Col xs={24} className="flex justify-center mt-8">
                <img src={empty} alt="No image found" />
              </Col>
            ) : presentations.length ? (
              presentations
                .filter((item) => item.status !== 4)
                .map((item) => {
                  return (
                    <Col key={item.id} lg={8} sm={12} xs={22} className="my-4">
                      <PresentationCard external presentation={item} />
                    </Col>
                  );
                })
            ) : (
              <>No Presentations</>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};
