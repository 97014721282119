import quiz1 from 'assets/svg/quiz1.svg';
import worldCloud from 'assets/svg/wordCloud.svg';
import quizzes from 'assets/svg/quiz3.svg';
import settingsIcon from 'assets/svg/settings-polls.svg';
import qrCode from 'assets/svg/qr-code-icon.svg';
import champion from 'assets/svg/champion.svg';
import participant from 'assets/svg/participant.svg';
import { useState } from 'react';

type PollTypes =
  | 'default'
  | 'multi_question'
  | 'world_cloud'
  | 'quizzes'
  | 'open_ended';

interface VariablePollTypeInterface {
  title: string;
  image: string;
  type: PollTypes;
}

export default function MainContent() {
  const [pollType, setPollType] = useState<PollTypes>('default'); //for handling poll type

  const variable_poll_type: VariablePollTypeInterface[] = [
    {
      title: 'Multi question',
      image: quiz1,
      type: 'multi_question',
    },
    {
      title: 'World cloud',
      image: worldCloud,
      type: 'world_cloud',
    },
    {
      title: 'Quizzes',
      image: quizzes,
      type: 'quizzes',
    },
    {
      title: 'Open ended',
      image: quizzes,
      type: 'open_ended',
    },
  ];

  return (
    <section className='p-3'>
      <section className='grid gap-x-3 grid-cols-10'>
        {/* Question type */}
        <section className='col-span-3'>
          <div className='w-full bg-white p-5 rounded-md '>
            <p className='text-[##667084] font-semibold text-sm'>
              SELECT QUESTION TYPE
            </p>
            {/* Questions */}
            <section className='mt-3 grid grid-cols-1 text-sm font-semibold text-[##333333] gap-y-3'>
              {variable_poll_type.map((item) => (
                <>
                  {(pollType === 'default' || pollType === item.type) && (
                    <div
                      key={item.title}
                      className={`rounded-md border py-2 px-3 flex gap-x-3 items-center cursor-pointer ${
                        pollType === item.type ? 'bg-[#F2F2F2]' : ''
                      }`}
                      onClick={() =>
                        setPollType((prev) =>
                          prev === 'default' ? item.type : 'default'
                        )
                      }
                    >
                      <img
                        src={item.image}
                        alt='multi question'
                        className='w-[26px] h-[26px]'
                      />
                      <p>{item.title}</p>
                    </div>
                  )}
                </>
              ))}
              {pollType === 'multi_question' && (
                <section className='text-sm'>
                  <div>
                    <p className='mb-2'>QUESTION</p>
                    <textarea
                      name='question'
                      id='question'
                      className='h-[110px] w-full resize-none bg-[#F6F3EF] rounded-md p-3'
                      placeholder='Type your questions'
                    ></textarea>
                  </div>

                  <div>
                    <p>CREATE YOUR OPTIONS?</p>
                    <button></button>
                    <label htmlFor=''>
                      <input
                        type='text'
                        className=''
                        placeholder='Type another choice'
                      />
                    </label>
                  </div>
                  <div>
                    <p>Show result as percentage</p>
                  </div>

                  <div>
                    <p>Duration</p>
                    <div>
                      <input type='number' placeholder='Mins' />
                      <input type='number' placeholder='Secs' />
                    </div>
                  </div>

                  <button>Save</button>
                </section>
              )}
            </section>
          </div>
        </section>
        {/* Question display */}
        <section className='col-span-6'>
          <div className='bg-white p-3 rounded-md'>
            <p>Your questions will appear here</p>
            {/* Content */}
            <section className='bg-gray550 rounded-md'>
              <section className=' px-5 pt-20 py-5 grid grid-cols-4 gap-x-5 mt-5 '>
                <div className='flex flex-col'>
                  <div className='h-[200px] rounded-md overflow-hidden'>
                    <div className='bg-[#EAECF0] w-full h-[95%]'></div>
                    <div className='h-[5%] bg-[#FFC043] w-full'></div>
                  </div>
                  <div className='flex items-center justify-center w-full'>
                    <p className='text-sm font-medium text-[#344054]'>
                      Option 1
                    </p>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='h-[200px] rounded-md overflow-hidden'>
                    <div className='bg-[#EAECF0] w-full h-[95%]'></div>
                    <div className='h-[5%] bg-black w-full'></div>
                  </div>
                  <div className='flex items-center justify-center w-full'>
                    <p className='text-sm font-medium text-[#344054]'>
                      Option 1
                    </p>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='h-[200px] rounded-md overflow-hidden'>
                    <div className='bg-[#EAECF0] w-full h-[95%]'></div>
                    <div className='h-[5%] bg-[#7356BF] w-full'></div>
                  </div>
                  <div className='flex items-center justify-center w-full'>
                    <p className='text-sm font-medium text-[#344054]'>
                      Option 1
                    </p>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='h-[200px] rounded-md overflow-hidden'>
                    <div className='bg-[#EAECF0] w-full h-[95%]'></div>
                    <div className='h-[5%] bg-[#3AA76D] w-full'></div>
                  </div>
                  <div className='flex items-center justify-center w-full'>
                    <p className='text-sm font-medium text-[#344054]'>
                      Option 1
                    </p>
                  </div>
                </div>
              </section>

              {/* Participant */}
              <section className='p-5'>
                <section className='flex rounded-full gap-x-5 py-2 px-5 bg-[#E8E8E8] w-fit'>
                  <div className='flex gap-x-2'>
                    <div>
                      <img src={participant} alt='number of participant' />
                    </div>
                    <div>
                      <p className='text-sm'>No of participant</p>
                      <p className='font-semibold'>0</p>
                    </div>
                  </div>
                  <div className='flex gap-x-2'>
                    <div>
                      <img src={champion} alt='number of participant' />
                    </div>
                    <div>
                      <p className='text-sm'>No of participant</p>
                      <p className='font-semibold'>0</p>
                    </div>
                  </div>
                </section>
              </section>
            </section>
          </div>
        </section>
        {/* Settings */}
        <section className='col-span-1 text-xs text=[#667084]'>
          <div className='bg-white rounded-md w-full flex items-center flex-col pt-3 pb-3'>
            <div className='mb-2'>
              <p className='font-bold'>SETTINGS</p>
              <hr className='w-full border ' />
            </div>
            <section className='font-medium grid grid-cols-1 gap-7'>
              <div className='flex flex-col items-center gap-1'>
                <img src={settingsIcon} alt='display result' />
                <p>Display result</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input type='checkbox' value='' className='sr-only peer' />
                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
              <div className='flex flex-col items-center gap-1'>
                <img src={settingsIcon} alt='display result' />
                <p>Live response</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input type='checkbox' value='' className='sr-only peer' />
                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
              <div className='flex flex-col items-center gap-1'>
                <img src={qrCode} alt='QR code' />
                <p>Display result</p>
                <label className='inline-flex items-center cursor-pointer'>
                  <input type='checkbox' value='' className='sr-only peer' />
                  <div className="relative w-[42px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-[22px] rtl:peer-checked:after:-translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
                </label>
              </div>
            </section>
          </div>
        </section>
      </section>
    </section>
  );
}
