import React, { useState } from 'react';

// Assets
import ellipsis from 'assets/svg/ellipsis-horizontal.svg';
import eyeIcon from 'assets/svg/eye.svg';
import copyIcon from 'assets/svg/copy-1.svg';
import eye2 from 'assets/svg/eye-2.svg';
import trash from 'assets/svg/trash.svg';
import rename from 'assets/svg/rename.svg';
import engagement from 'assets/svg/engagement-icon.svg';
import activity from 'assets/svg/activity-log-icon.svg';

// Libraries
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import moment from 'moment';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { TableEmpty } from 'components/UI/States/table/TableEmpty';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
// Extend dayjs with UTC plugin
dayjs.extend(utc);
interface RowData {
  name: string;
  link: string;
  visits: number;
  dateCreated: string;
  private: boolean;
}

interface ShareHistoryInterface {
  id: string;
  spaceId: string;
  title: string;
  friendlyId: string;
  dateCreated: string; // ISO date string
  views: number;
  published: boolean;
}

export default function ShareHistory() {
  //Types
  const navigate = useNavigate();

  // States
  const [selectedFiles, setSelectedFiles] = useState<RowData[]>([]);
  const shareHistory = useSelector(
    (state: RootState) => state.briefcase.briefcaseHistory.shareHistory
  );

  const copyLink = async (friendlyId: string) => {
    let routeOrigin = window.location.origin;
    let text = `${routeOrigin}/shared-briefcase/${friendlyId}`;

    try {
      await navigator.clipboard.writeText(text);

      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  //variables
  const items = [
    // {
    //   key: '1',
    //   action: 'preview',
    //   label: (
    //     <div className='flex gap-x-2 pl-2 pr-5'>
    //       <img src={eyeIcon} alt='create new folder' className='w-5' />
    //       <p>Preview</p>
    //     </div>
    //   ),
    // },
    // {
    //   key: '2',
    //   action: 'edit',
    //   label: (
    //     <div className='flex gap-x-2 pl-2 pr-5'>
    //       <img src={rename} alt='rename file' className='w-5' />
    //       <p>Edit</p>
    //     </div>
    //   ),
    // },
    {
      key: '3',
      action: 'activity',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={activity} alt='rename file' className='w-5' />
          <p>Activity log</p>
        </div>
      ),
    },
    {
      key: '4',
      action: 'engagement',
      label: (
        <div className='flex gap-x-2 pl-2 pr-5'>
          <img src={engagement} alt='rename file' className='w-5' />
          <p>Engagement metrics</p>
        </div>
      ),
    },

    {
      key: '5',
      action: 'delete',
      label: (
        <div className='flex gap-x-2 cursor-pointer pl-2 pr-5'>
          <img src={trash} alt='delete file' className='w-5' />
          <p>Delete link</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      header: 'Name',
      field: 'name',
      body: (rowData: ShareHistoryInterface) => {
        return (
          <section className=''>
            <section className='flex items-center gap-x-4'>
              <p className='text-black text-sm'>{rowData?.title}</p>
            </section>
          </section>
        );
      },
    },
    {
      header: 'Links',
      field: 'links',
      body: (rowData: ShareHistoryInterface) => {
        return (
          <div className='flex gap-x-2 items-center'>
            <p className='text-sm text-black italic'>
              thepresenta.com/shared...
            </p>
            <img
              src={copyIcon}
              alt='copy'
              onClick={() => copyLink(rowData.friendlyId)}
              className='cursor-pointer'
            />
          </div>
        );
      },
    },
    {
      header: 'Visits',
      field: 'visits',
      sortable: true,
      body: (rowData: ShareHistoryInterface) => {
        return (
          <section className='w-full flex gap-x-2'>
            <div className='flex items-center gap-x-2 bg-[#F6F3EF] border border-[#DFDFDF] rounded-full w-fit px-2'>
              <img src={eyeIcon} alt='profile' className='' />
              <div className='text-sm font-medium capitalize'>
                <p className='text-sm text-black'>{rowData.views}</p>
              </div>
            </div>
          </section>
        );
      },
    },

    {
      header: 'Date created',
      field: 'dateCreated',
      body: (rowData: ShareHistoryInterface) => {
        const relativeTime = moment.utc(rowData.dateCreated).fromNow();

        return (
          <div className='flex'>
            <p className='text-black text-sm'>{relativeTime}</p>
          </div>
        );
      },
    },

    {
      header: 'Published',
      field: 'published',
      body: (rowData: ShareHistoryInterface) => {
        return (
          <section className='flex gap-x-5 items-center'>
            <p className='text-sm text-black'>Active</p>

            {/* <label className='inline-flex items-center cursor-pointer'>
              <input type='checkbox' value='' className='sr-only peer' />
              <div className="relative w-9 h-5 bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
            </label> */}
            <label className='inline-flex items-center cursor-pointer'>
              <input
                type='checkbox'
                value=''
                checked={rowData.published}
                className='sr-only peer'
                // onChange={handleIsPrivate}
              />
              <div className="relative w-[36px] h-[20px] bg-[#EAECF0] peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all peer-checked:bg-black"></div>
            </label>
          </section>
        );
      },
    },

    {
      header: '',
      sortable: true,
      body: (rowData: ShareHistoryInterface) => {
        return (
          <div className='w-[100px] flex justify-end'>
            <div className='cursor-pointer flex items-center gap-x-5'>
              {/* <img
                src={eye2}
                alt=''
                className='w-5'
                onClick={() => {
                  navigate(`/shared-briefcase/${rowData.friendlyId}`);
                }}
              /> */}
              <section onClick={(e) => e.preventDefault()}>
                <Dropdown
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <Menu>
                      {items.map((item) => {
                        return (
                          <Menu.Item key={item.key}>{item.label}</Menu.Item>
                        );
                      })}
                    </Menu>
                  )}
                >
                  <div className='cursor-pointer py-1'>
                    <img src={ellipsis} alt='options' />
                  </div>
                </Dropdown>
              </section>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {/* Table */}
      {/* {shareHistory.length > 0 && ( */}
      <section className='overflow-hidden rounded-xl w-full'>
        <DataTable
          value={shareHistory}
          tableStyle={{ minWidth: '45rem', maxHeight: '300px' }}
          size={'normal'}
          emptyMessage={TableEmpty}
          // selectionMode='checkbox'
          // selection={selectedFiles}
          // onSelectionChange={(e) => {
          //   setSelectedFiles(e.value);
          // }}
          dataKey='id'
        >
          <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} />
          {columns.map((col, index) => {
            return (
              <Column
                key={index}
                body={col?.body}
                field={col.field}
                header={col.header}
                headerClassName='bg-+[#F5F5F5F5] text-blue300 font-normal text-sm'
                className='text-sm'
              />
            );
          })}
        </DataTable>
      </section>
      {/* )} */}
    </div>
  );
}
