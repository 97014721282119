import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

//assets
import add from 'assets/svg/addImg.svg';
import globe from 'assets/svg/globe2.svg';
import poll from 'assets/svg/darkPoll.svg';
import folder from 'assets/svg/folder3.svg';
import oneDrive from 'assets/svg/oneDrive.svg';
import googleDrive from 'assets/svg/google-drive.svg';

//libraries
import { Drawer, Modal } from 'antd';

//components
// import UploadFromComputer from './UploadFromComputer';
import UploadFromWorkspace from 'views/Admin/Presentation/Drawers/UploadFromWorkspace';

import { openPollDrawer } from 'store/UI';
import UploadDocuments from './UploadDocuments';

export default function AddFile({ closeAddFileModal, spaceId, groupId }) {
  const dispatch = useDispatch();
  const fromComputerRef = useRef();

  //usestate
  const [openViewersDrawer, setOpenViewersDrawer] = useState(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);

  //functions

  const handleFileUploadCancel = () => {
    setIsFileUploadModalOpen(false);
    fromComputerRef.current?.resetModal();
  };
  const onClose = () => {
    setOpenViewersDrawer(false);
  };

  return (
    <section>
      <div>
        <h5 className='mt-4 font-semibold text-center text-md'>
          Where do you want to upload from?
        </h5>
      </div>
      <section className='w-[95%] mx-auto py-10 '>
        <section className='grid lg:grid-cols-3 gap-x-5 gap-y-4'>
          <div
            onClick={() => setIsFileUploadModalOpen(true)}
            className='flex flex-col items-center py-8 text-sm text-center border-2 rounded-lg cursor-pointer gap-y-4'
          >
            <div className='flex justify-center w-full '>
              <img src={add} alt='upload from computer' className='w-6' />
            </div>

            <p>Upload from computer</p>
          </div>

          <div
            onClick={() => setOpenViewersDrawer(true)}
            className='flex flex-col py-8 text-sm text-center border-2 rounded-lg cursor-pointer gap-y-4 bg-gray200'
          >
            <div className='flex justify-center w-full gap-y-3'>
              <img src={folder} alt='upload from folder' className='w-9' />
            </div>

            <p>Upload from folder</p>
          </div>

          <div
            className='flex flex-col py-8 text-sm text-center border-2 rounded-lg cursor-pointer gap-y-4 bg-gray200'
            //   onClick={() => setIsAddFileModalOpen(true)}
          >
            <div className='flex justify-center w-full gap-y-3'>
              <img src={googleDrive} alt='upload from folder' className='w-9' />
            </div>

            <p>Google drive</p>
          </div>

          {/*  */}
        </section>
      </section>

      <>
        {/* Modal */}

        <Modal
          open={isFileUploadModalOpen}
          onCancel={handleFileUploadCancel}
          footer={null}
        >
          <UploadDocuments
            closeModal={handleFileUploadCancel}
            spaceId={spaceId}
            groupId={groupId}
          />
        </Modal>
        {/*  */}

        {/* Drawer  */}
        <Drawer
          closable={false}
          onClose={onClose}
          width='85%'
          open={openViewersDrawer}
          bodyStyle={{ padding: '0px' }}
        >
          <UploadFromWorkspace
            closeAddFileModal={closeAddFileModal}
            closeUploadFromWorkspaceModal={onClose}
          />
        </Drawer>

        {/*  */}
      </>
    </section>
  );
}
