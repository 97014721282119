import React, { useEffect, useState } from "react";
import logoReduced from "assets/svg/logoReduced.svg";
import { Col, Row } from "antd";
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";
import { TextInput } from "components/UI/Inputs/text-type-input";
import { CButton } from "components/UI/Button/Buttons";
import logo from "assets/svg/fullLogo.svg";
import { useNavigate } from "react-router-dom";
import { ZoomConfigInterface } from "interfaces/interfaces-data";
import { toast } from "sonner";

const ExLandingPage = () => {
  const [meetingCode, setMeetingCode] = useState("0");
  const navigate = useNavigate();
  const [zoomResponse, setZoomResponse] = useState<ZoomConfigInterface>();
  const [loading, setLoading] = useState(true);
  const [testData, setTestData] = useState("");
  const [meetingId, setMeetingId] = useState("");

  const navigateToJoinMeeting = () => {
    if (!meetingId) {
      toast.error("Meeting id is required");
      return;
    } else if (meetingId.length !== 10 || isNaN(+meetingId)) {
      toast.error("Invalid meeting id entered");
      return;
    }
    navigate(`/join-presentation/${meetingId}`);
  };

  const startZoomMeeting = async () => {
    try {
      const response = await zoomSdk.launchAppInMeeting();
    } catch (error) {
      console.log("error result", error);
    }
  };

  const getConfiguration = async () => {
    try {
      setLoading(true);
      const configResponse = await zoomSdk.config({
        popoutSize: { width: 480, height: 360 },
        capabilities: [
          "shareApp",
          "getMeetingParticipants",
          "getUserContext",
          "getRunningContext",
          "getMeetingContext",
        ],
      });

      setZoomResponse(configResponse);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   toast.info("Use effect triggered");
  //   console.log("Use Effect triggered");
  //   const loadZoomSdk = () => {
  //     toast.info("Started After page load ");
  //     const script = document.createElement("script");
  //     script.src = "https://appssdk.zoom.us/sdk.js";
  //     script.async = true;

  //     script.onload = async () => {
  //       // SDK is loaded
  //       toast.info("Script loaded");
  //       await getConfiguration();
  //     };
  //     document.body.appendChild(script);
  //   };
  //   loadZoomSdk();
  // }, []);

  window.addEventListener("load", () => {
    getConfiguration();
  });

  return (
    <div className="h-screen max-h-screen min-h-screen items-center justify-center bg-[#F2F2F2]">
      <Row justify={"center"} className=" h-full" align={"middle"}>
        <Col lg={12} xl={8} md={18} xs={24}>
          <div className="lg:bg-[#fff] p-4 rounded-lg">
            <div className="lg:flex hidden  justify-center">
              <img src={logoReduced} alt="" />
            </div>
            <div className="flex justify-center lg:hidden">
              <img src={logo} alt="" />
            </div>
            <div className="mt-8">
              <p className="lg:text-xl text-lg  font-grotesk font-semibold">
                Hi, what will you like to do ?
              </p>
            </div>
            {loading ? (
              "Loading..."
            ) : zoomResponse?.runningContext == "inMainClient" ? (
              <div>
                <CButton
                  text="Start a zoom meeting"
                  variant="orangeBg"
                  fullWidth
                  action={() => {
                    startZoomMeeting();
                  }}
                />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-between w-full">
                <div className="border-[#E2E4E9] w-full my-8 border bg-[#FFFFFF] rounded-2xl p-2">
                  <div className="bg-[#F6F6F6] rounded-2xl p-4">
                    <p className="text-[#474747] font-grotesk font-bold mb-3 text-sm lg:text-base">
                      Host a meeting
                    </p>

                    <CButton
                      action={() => navigate("/external/presentations")}
                      variant="orangeBg"
                      fullWidth
                      text="Start"
                    />
                  </div>
                </div>

                <p className="justify-center font-grotesk lg:text-lg text-base font-semibold">
                  Or
                </p>
                <div className="border-[#E2E4E9] w-full my-8 border bg-[#FFFFFF] rounded-2xl p-2">
                  <div className="bg-[#F6F6F6] rounded-2xl p-4">
                    <p className="text-[#474747] font-grotesk font-bold text-sm lg:text-base">
                      Join a meeting
                    </p>
                    <div className="mt-2">
                      <TextInput
                        name="meetingCode"
                        placeholder="000-000"
                        label="Enter your meeting code"
                        change={setMeetingId}
                      />
                      <div className="mt-4">
                        <CButton
                          variant="dark"
                          fullWidth
                          text="Join a meeting"
                          action={() => navigateToJoinMeeting()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExLandingPage;
