import MainContent from './MainContent';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

export default function PollSetup() {
  return (
    <section className='w-full relative bg-[#F2F2F2]'>
      {/* Navbar and Sidebar */}
      <section>
        <Navbar />
        <Sidebar />
      </section>
      {/* main content */}
      <section className='mt-[80px] absolute right-0 top-0 w-[88%] min-h-fit '>
        <MainContent />
      </section>
    </section>
  );
}
