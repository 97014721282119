import { useState } from 'react';

// Assets
import folder from 'assets/svg/folderImg.svg';
import chevronDown from 'assets/svg/chevron-down-black.svg';
import sectionIcon from 'assets/svg/section-rectangle-group.svg';
import imageTypeIcon from 'assets/svg/imagetype-icon-jpeg-png.svg';

// Interface
import { DocCategory } from 'interfaces/SharedPresentation';

// Slice
import { useDispatch, useSelector } from 'react-redux';
import { setDisplayDoc } from 'store/SharedPresentations/sharedPresentationsSlice';
import { AppDispatch, RootState } from 'store/store';

export default function Folder({ doc }: { doc: DocCategory }) {
  const dispatch = useDispatch<AppDispatch>();

  // State
  const [isOpen, setIsOpen] = useState(true);

  // Store
  const displayDocuments = useSelector(
    (state: RootState) => state.sharePresentation.flattedDisplayDocuments
  );
  const displayDocumentIndex = useSelector(
    (state: RootState) => state.sharePresentation.displayDocIndex
  );

  return (
    <section>
      <section
        className='flex justify-between items-center px-2 py-1 bg-[#EEEEEE] rounded-lg cursor-pointer'
        // className='flex justify-between items-center px-2 py-1  border border-[#D7D7D7] rounded-lg cursor-pointer'

        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className='flex gap-x-2 items-center'>
          <img src={sectionIcon} alt='folder' className='w-4' />
          <p className='text-sm font-medium'>{doc.title}</p>
        </div>
        {/* <div>
          <img src={chevronDown} alt='folder' className='w-5' />
        </div> */}
      </section>
      {/* Folder documents */}
      <section className='px-5 py-2'>
        {isOpen &&
          doc.docs.map((doc) => (
            <div
              className={`py-1 px-1 cursor-pointer flex gap-x-2 items-center${
                displayDocuments[displayDocumentIndex].id === doc.id
                  ? 'bg-[#F6F3EF] border border-[#D7D7D7] rounded-md my-1'
                  : ''
              }`}
              onClick={() => {
                dispatch(setDisplayDoc(doc.id));
              }}
            >
              <img src={imageTypeIcon} alt='type' className='w-5' />
              <p className='text-sm'>{doc.title}</p>
            </div>
          ))}
      </section>
    </section>
  );
}
